<template>
  <header class="tw-select-none" :class="{ 'tw-whitespace-nowrap': !wrap }">
    <div class="tw-flex tw-gap-3 tw-items-center tw-max-w-full">
      <div v-if="logoUrl" :title="title">
        <img class="tw-max-w-full tw-max-h-full" :alt="title" :src="logoUrl" />
      </div>
      <HeaderTitle
        v-else
        :level="level"
        class="tw-m-0 tw-min-w-0 tw-max-w-full tw-flex-initial"
        :class="[
          `t-${displayLevel || 6 - level}`,
          { 'tw-truncate': !wrap, 'tw-min-w-fit': alwaysShowTitle },
        ]"
      >
        {{ processedTitle }}
      </HeaderTitle>
      <div v-if="slots.default" class="tw-flex-initial tw-ml-auto">
        <slot />
      </div>
    </div>
    <slot name="byline" />
  </header>
</template>

<script lang="ts" setup>
import { computed, PropType, useSlots } from 'vue'
import HeaderTitle from './HeaderTitle'

const props = defineProps({
  title: { type: String as PropType<string>, required: true },
  logoUrl: { type: String as PropType<string>, required: false },
  alwaysShowTitle: { type: Boolean as PropType<boolean>, required: false, default: false },
  wrap: { type: Boolean as PropType<boolean>, required: false, default: false },
  displayLevel: { type: Number as PropType<number>, required: false, default: 0 },
  level: { type: Number as PropType<number>, required: false, default: 1 },
})
const slots = useSlots()
const processedTitle = computed(() => {
  const titleParts = props.title.split(/\s+/)
  const lastWord = titleParts.pop()
  return titleParts.join(' ') + '\xA0' + lastWord
})
</script>
