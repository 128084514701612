<template>
  <ul class="category-path">
    <li v-for="cat in getParents" :key="cat.id" class="category-item d-inline ma-0">
      <router-link
        :to="{ name: 'category', params: { id: cat.id } }"
        class="category-link color-fg ma-0"
        :title="cat.name"
      >
        {{ cat.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    category: Object,
    depth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getParents() {
      var cat = this.category
      var parents = []
      var hasParent = true
      while (hasParent) {
        parents.push(cat)
        if (cat.parent && cat.parent != null) {
          cat = cat.parent
        } else {
          hasParent = false
        }
      }
      return parents.reverse()
    },
  },
}
</script>

<style lang="scss">
.category-path {
  display: inline-flex;
  margin: 5px 10px 5px 0px;
  padding-left: 0rem;
}
.category-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 0.35rem;
  border-radius: 2px;
  background-color: hsla(var(--color-base-base), calc(var(--color-base-lightness) + 10%), 0.25);
}
.category-item {
  margin: 0;
}

.category-item:not(:last-child) a {
  color: hsla(var(--color-foreground-base), calc(var(--color-foreground-lightness) + 10%), 0.65);
}

.category-item:last-of-type a {
  background-color: hsla(var(--color-base-base), calc(var(--color-base-lightness) + 20%), 0.65);
}
</style>
