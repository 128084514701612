import { h, inject, ref } from 'vue'
import { useRoute } from 'vue-router'

export const oneOf = (values) => (value) => values.indexOf(value) !== -1

export const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream

export const renderSimpleTemplateString = (str, ctx) => {
  return str.replace(/\{([a-z_]+)\}/gi, (match, key) => ctx[key] || '')
}

export const delay = (time, data) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, time)
  })

export const deferred = () => {
  let resolver
  const promise = new Promise((resolve) => {
    resolver = resolve
  })
  promise.resolve = (value) => resolver(value)
  return promise
}

export const clamp = (value, min, max) => Math.min(max, Math.max(min, value))

export const createQueryString = (queryArgs) => new URLSearchParams(queryArgs).toString()

export async function createCollectionLoader({
  source,
  injectAs,
  component,
  notFound = null,
  routeParam = 'id',
}) {
  const cmp = await component.then((module) => module.default)

  return {
    name: 'app-collection-loader',
    setup(props, { slots }) {
      const data = ref(null)
      const hasLoaded = ref(false)
      const dataSources = inject('dataSources')
      const route = useRoute()

      async function load() {
        try {
          data.value = await dataSources[source].get(route.params[routeParam])
        } finally {
          hasLoaded.value = true
        }
      }

      load()

      return () => {
        if (!hasLoaded.value) return h('app-spinner-area')
        if (!data.value) return h(notFound)
        return h(cmp, { [injectAs]: data.value }, slots?.default?.())
      }
    },
  }
}

export function secondsToDuration(seconds, max = null) {
  max = max === null ? seconds : max
  const start = max <= 599 ? 15 : max <= 3599 ? 14 : 11
  const date = new Date(null)
  date.setSeconds(seconds)
  return date.toISOString().substring(start, 19)
}

export function createBroadcastTitle(broadcast, prefix) {
  const title = broadcast.title.trim()
  if (!prefix) {
    return title
  } else {
    if (title) {
      return `${prefix}: ${title}`
    } else {
      return prefix
    }
  }
}

export function padLeadingZero(value) {
  return String(value).padStart(2, '0')
}

export function getISODate(date) {
  const day = padLeadingZero(date.getDate())
  const month = padLeadingZero(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}
