<template>
  <PlayerControl :disabled="!$player.canSeek" @click="$player.seekRelative(seekTime)">
    <svg
      width="24.76544"
      height="24"
      viewBox="0 0 24.76544 24"
      xmlns="http://www.w3.org/2000/svg"
      style="width: 19px"
    >
      <path
        d="m 3.8778229,5.8949305 h 2.3167568 c 1.5728145,-1e-7 1.5728145,2.3592226 0,2.3592227 0,0 -3.3961106,0 -5.20907231,0.00449 C 0.57342659,8.2596634 -0.00348095,7.8723846 1.5820704e-5,7.2866494 L 0.00240641,2.06198 c 4.8587e-4,-1.57232841 2.35970869,-1.57232841 2.35922279,0 V 4.0594553 C 7.6908906,-1.575054 16.740152,-1.3070945 21.726696,4.6328478 26.713238,10.57279 25.409878,19.531707 18.937528,23.804575 17.639935,24.610639 16.38687,22.718934 17.635237,21.838555 22.977689,18.310231 24.005004,10.891319 19.822373,6.0440206 15.639741,1.1967216 8.1503677,1.1266789 3.8778229,5.8949305 Z"
        fill="currentColor"
        transform-origin="center"
        :transform="direction === 'forward' ? 'scale(-1, 1)' : ''"
      />
      <text fill="currentColor" :x="direction === 'forward' ? '10' : '-1'" y="23.5">
        {{ Math.abs(seekTime) }}
      </text>
    </svg>
  </PlayerControl>
</template>

<script>
import PlayerControl from './PlayerControl.vue'

const SEEK_TIME = 15

export default {
  components: { PlayerControl },
  props: {
    direction: {
      type: String,
      choices: ['backward', 'forward'],
      required: true,
    },
  },
  computed: {
    seekTime() {
      return this.direction === 'forward' ? SEEK_TIME : -SEEK_TIME
    },
  },
}
</script>
