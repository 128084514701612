<template>
  <component :is="tag || 'div'" class="grid ma-0 pa-0" :style="style">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    sizes: Object,
    gapModifier: {
      type: Number,
      default: 1,
    },
    defaultSize: {
      type: Number,
      default: 1,
    },
    tag: String,
  },
  computed: {
    style() {
      const size = (this.sizes && this.sizes[this.$mq.value]) || this.defaultSize
      return {
        'grid-gap': `calc(1.5rem * ${this.gapModifier})`,
        'grid-template-columns': Array.isArray(size)
          ? size.map((size) => `${size}fr`).join(' ')
          : size === 1
          ? 'repeat(auto-fit, 100%)'
          : `repeat(${size}, minmax(0, 1fr))`,
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  display: grid;

  > li {
    display: block;
  }
}
</style>
