<template>
  <AppButton
    v-if="canShare"
    v-bind="$attrs"
    :title="label"
    :aria-label="label"
    @click.prevent="shareData"
  >
    <template #icon>
      <component :is="shareIcon" />
    </template>
    <slot />
  </AppButton>
</template>

<script>
import Share2Icon from '~icons/feather/share'
import ShareIcon from '~icons/feather/share2'

import { isIOS } from '../../util'

export default {
  props: {
    name: String,
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canShare() {
      return 'share' in window.navigator
    },
    shareIcon() {
      return isIOS ? ShareIcon : Share2Icon
    },
    label() {
      return this.$t(this.name ? 'share_label' : 'share_label_default', [this.name])
    },
  },
  methods: {
    shareData() {
      window.navigator.share(this.payload)
    },
  },
}
</script>

<i18n lang="yaml">
en:
  share_label: 'Share {0}'
  share_label_default: Share content

de:
  share_label: '{0} teilen'
  share_label_default: Inhalt teilen
</i18n>
