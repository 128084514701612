import { defineComponent, h, PropType } from 'vue'

export default defineComponent({
  props: {
    level: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props, { slots }) {
    return () => {
      return h('h' + props.level, slots?.default?.())
    }
  },
})
