<template>
  <div class="input d-flex">
    <input
      v-bind="$attrs"
      ref="inputEl"
      v-model="localValue"
      :type="type"
      :aria-label="label"
      :list="autocompleteList ? id : null"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :name="name"
    />
    <slot />
    <datalist v-if="autocomplete" :id="id">
      <option v-for="option in options" :key="option.value" :value="option.value"></option>
    </datalist>
  </div>
</template>

<script>
import { throttle } from 'throttle-debounce'

export default {
  props: {
    id: String,
    name: String,
    value: String,
    type: String,
    label: String,
    autofocus: Boolean,
    autocomplete: String,
    autocompleteList: Function,
    placeholder: String,
    autocompleteThreshold: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    value: throttle(200, async function (newValue) {
      if (this.autocompleteList && newValue && newValue.length > this.autocompleteThreshold) {
        this.options = await this.autocompleteList(newValue)
      }
    }),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.autofocus && document.activeElement !== this.$el) {
        this.$refs.inputEl.focus()
      }
    })
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.input {
  align-items: stretch;
  width: 100%;
}

.input input {
  display: block;
  border: none;
  background: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.95);
  color: var(--color-base);
  padding: 0.75em 1em;
  width: 100%;
  flex: 1;
  border-radius: 2px;

  &::placeholder {
    color: hsl(var(--color-base-base), calc(var(--color-base-lightness) + 45%));
  }

  + .button {
    margin-left: 0.5rem;
  }

  &[type='search'] {
    border-radius: 500px;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}
</style>
