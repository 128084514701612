import { IDBStore } from './util/persistance'
import { createStore, sync } from './util/state'

const store = new IDBStore('thekno')
export const { mapState, mapRef } = createStore(
  {
    bookmarks: {
      broadcasts: [],
      recordings: [],
      series: [],
    },
    player: {
      volume: 100,
      isMuted: false,
    },
  },
  [
    sync(store, 'bookmarks.broadcasts'),
    sync(store, 'bookmarks.recordings'),
    sync(store, 'bookmarks.series'),
    sync(store, 'player.volume'),
    sync(store, 'player.isMuted'),
  ],
)
