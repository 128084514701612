<template>
  <component :is="cardLink.is" class="card d-flex" :class="cssClasses" v-bind="cardLink.data">
    <header class="card-header d-flex" :class="isVertical ? 'mb-2' : 'mr-2'">
      <slot name="image" :is-minimal="isMinimal" :is-vertical="isVertical">
        <app-image v-if="!isMinimal && image" v-bind="imageData" />
      </slot>
    </header>
    <div class="card-content d-flex">
      <component :is="bodyLink.is" class="card-body" v-bind="bodyLink.data">
        <slot>
          <strong class="card-title f-2 mb-2" :class="{ 'tw-truncate': !showFullTitle }">{{
            title ?? ''
          }}</strong>
          <app-clamp
            v-if="!isMinimal && description"
            autoresize
            :max-lines="maxDescriptionLines"
            :text="description ?? ''"
            class="card-description f-1 mt-1"
          />
        </slot>
      </component>

      <footer class="card-footer">
        <slot name="footer"></slot>
      </footer>
    </div>
  </component>
</template>

<script>
function createLinkData(data, isLink) {
  return data && isLink ? { is: 'router-link', data } : { is: 'div' }
}

export default {
  props: {
    title: String,
    description: String,
    image: Object,
    link: Object,
    isMinimal: Boolean,
    isVertical: Boolean,
    isCentered: Boolean,
    hasBodyLink: Boolean,
    fillImage: Boolean,
    showFullTitle: Boolean,
    maxDescriptionLines: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    imageData() {
      return {
        size: this.isVertical ? 96 : null,
        aspectRatio: 1,
        ...(this.image || {}),
      }
    },
    cssClasses() {
      return {
        'is-vertical': this.isVertical,
        'is-centered': this.isCentered,
        'fill-image': this.fillImage,
      }
    },
    linkData() {
      return this.link ? { title: this.title, ...this.link } : null
    },
    bodyLink() {
      return createLinkData(this.linkData, this.hasBodyLink)
    },
    cardLink() {
      return createLinkData(this.linkData, !this.hasBodyLink)
    },
  },
}
</script>

<style lang="scss">
@import './../../styles/util';

.card {
  align-items: stretch;

  .card-header {
    flex: 0 0 auto;
  }

  .image {
    flex: 1;
  }

  &.is-vertical {
    flex-direction: column;
    max-width: none;

    .card-header {
      min-height: 0;
      flex: 1;
    }

    .image {
      flex: 0 1 auto;
    }

    img {
      max-height: 100%;
      margin: 0 auto;
    }
  }

  &.fill-image .image {
    flex: 1;
  }

  &.is-centered,
  &.is-centered .card-header {
    justify-content: center;
    text-align: center;
  }
}

.card,
.card-body {
  text-decoration: none;
  color: var(--color-foreground);
}

.card-body {
  flex: 1;
  min-width: 0;
}

.card-content {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;

  .card.is-vertical & {
    flex: 0 0 auto;
  }
}

.card-footer {
  flex: 0 0 auto;
}

.card-title {
  display: block;
  max-width: 100%;
  min-width: 0;

  [href]:hover &,
  [href]:focus & {
    text-decoration: underline;
  }
}
</style>
