// @ts-ignore
import nmd from 'nano-markdown'
import { defineComponent, h } from 'vue'

type Node = string | { children: null | Node | string }

function findTextContent(node: Node | Node[]): string {
  if (Array.isArray(node)) {
    return node.map(findTextContent).join('')
  } else if (typeof node === 'string') {
    return node
  } else if (node && typeof node.children === 'string') {
    return node.children
  } else if (node && Array.isArray(node?.children)) {
    return findTextContent(node.children.flat())
  } else {
    return ''
  }
}

export default defineComponent({
  setup(props, { slots }) {
    return () => {
      const markdownContent = slots.default ? findTextContent(slots.default() as Node[]) : ''
      return h('div', { class: 'content', innerHTML: nmd(markdownContent) })
    }
  },
})
