<template>
  <div class="player-progress">
    <span v-if="!isMinimal" class="player-progress-label">{{ timeLabels.progress }}</span>
    <div class="player-progress-bar" :class="isMinimal ? null : 'mx-2'">
      <SeekableProgress
        v-model:model-value="value"
        :min="progress.min"
        :max="progress.max"
        :step="5"
        :step-factor="6"
        :knob-label="$t('player_set_seek')"
        :look="isMinimal ? 'plain' : 'default'"
        :editable="!isMinimal && $player.canSeek"
        :label="`${timeLabels.progress} / ${timeLabels.duration}`"
        @peek="seek = $event"
      />
    </div>
    <span v-if="!isMinimal" class="player-progress-label">{{ timeLabels.duration }}</span>
  </div>
</template>

<script>
import { secondsToDuration } from '../../util'
import SeekableProgress from '../SeekableProgress.vue'

export default {
  components: { SeekableProgress },
  props: {
    isMinimal: Boolean,
  },
  data() {
    return {
      seek: null,
    }
  },
  computed: {
    value: {
      get() {
        return this.progress.value
      },
      set(pos) {
        this.$player.seek(pos)
      },
    },
    progress() {
      const { currentTrack } = this.$player
      return currentTrack &&
        currentTrack.duration > 0 &&
        currentTrack.duration !== Number.POSITIVE_INFINITY
        ? {
            min: 0,
            max: this.$player.currentTrack.duration,
            value: this.$player.currentTrack.progress,
          }
        : { min: 0, max: 1, value: 0 }
    },
    timeLabels() {
      let { duration, progress } = this.$player.currentTrack || { duration: 0, progress: 0 }
      if (duration === Number.POSITIVE_INFINITY) {
        duration = progress = 0
      }
      if (this.seek) {
        progress = this.seek
      }
      return {
        duration: secondsToDuration(duration),
        progress: secondsToDuration(progress, duration),
      }
    },
  },
}
</script>

<style lang="scss">
.player-progress {
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-fixed-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.player-progress-label {
  color: var(--color-foreground-dimmed);
  font-size: 12px;
  font-variant-numeric: tabular-nums;
}

.player-progress-bar {
  width: 100%;
}
</style>

<i18n lang="yaml">
en:
  player_set_seek: Jump to position in track

de:
  player_set_seek: Zu Position im Track springen
</i18n>
