<template>
  <div :style="style">
    <Refresh />
    <NavBar />
    <router-view
      :key="routerViewKey"
      :style="{ transform: `translateY(${pullDistance}px)` }"
      class="tw-transition-transform tw-relative tw-z-10"
      :class="{ 'tw-transition-transform': !isPullRefreshActive }"
    />
    <ProviderData />
    <Player />
  </div>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import { computed, nextTick, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import Refresh from './components/generic/Refresh.vue'
import NavBar from './components/nav/NavBar.vue'
import ProviderData from './components/nav/ProviderData.vue'
import Player from './components/player/Player.vue'
import { useBreakpoint } from './mq'
import { oneOf } from './util'
import { usePullRefresh } from './util/dom'

function isTextField(field: Element | null) {
  if (!field) {
    return false
  } else if (field.nodeName === 'TEXTAREA') {
    return true
  } else if (field.nodeName === 'INPUT') {
    const type = field.getAttribute('type')?.toLowerCase?.()
    return oneOf(['text', 'password', 'number', 'search'])(type)
  } else {
    return false
  }
}

const route = useRoute()
const { locale } = useI18n()
const { pullDistance, isActive: isPullRefreshActive } = usePullRefresh()
const hasFocus = ref(false)
const breakpoint = useBreakpoint()
const style = computed(() =>
  breakpoint.value === 'xs' && hasFocus.value ? { 'screen-bottom-padding': '0' } : undefined,
)
const routerViewKey = computed(() => route.fullPath.split('#')[0])

async function handleFocus() {
  await nextTick()
  hasFocus.value = isTextField(document.activeElement)
}

if (!import.meta.env.SSR) {
  watchEffect(() => {
    document.documentElement.setAttribute('lang', locale.value)
  })

  watchEffect(() => {
    document.documentElement.setAttribute('class', `is-size-${breakpoint.value}`)
  })

  useEventListener(document, 'focusin', handleFocus, { passive: true })
  useEventListener(document, 'focusout', handleFocus, { passive: true })
}
</script>
