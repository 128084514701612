function delay(timeMs: number): Promise<void>
function delay<T>(timeMs: number, value: T): Promise<T>
function delay(timeMs: number, value?: any) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value)
    }, timeMs)
  })
}

export { delay }
