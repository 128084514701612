import { computed, Ref } from 'vue'

export function clamp(value: Ref<number>, min: number, max: number) {
  return computed(() => clampNumber(value.value, min, max))
}

export function clampPercentage(value: Ref<number>) {
  return clamp(value, 0, 100)
}

export function clampNumber(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max)
}

export function mapValueToDomain(
  value: number,
  inputDomain: [number, number],
  outputDomain: [number, number],
) {
  const [x1, y1] = inputDomain
  const [x2, y2] = outputDomain
  value = clampNumber(value, x1, y1)
  return ((value - x1) * (y2 - x2)) / (y1 - x1) + x2
}
