import { createRouter, createWebHistory } from 'vue-router'
import { createCollectionLoader } from './util'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/HomePage.vue'),
  },
  {
    path: '/series/:id',
    name: 'series',
    props: true,
    component: () =>
      createCollectionLoader({
        component: import('./views/SeriesPage.vue'),
        source: 'series',
        injectAs: 'series',
      }),
  },
  {
    path: '/recordings/:id',
    name: 'recording',
    props: true,
    component: () =>
      createCollectionLoader({
        component: import('./views/RecordingPage.vue'),
        source: 'recordings',
        injectAs: 'recording',
      }),
  },
  {
    path: '/categories/:id',
    name: 'category',
    props: true,
    component: () =>
      createCollectionLoader({
        component: import('./views/CategoryPage.vue'),
        source: 'categories',
        injectAs: 'category',
      }),
  },
  {
    path: '/broadcasts/:id',
    name: 'broadcast',
    props: true,
    component: () =>
      createCollectionLoader({
        component: import('./views/BroadcastPage.vue'),
        source: 'broadcasts',
        injectAs: 'broadcast',
      }),
  },
  {
    path: '/tags/:slug',
    name: 'tag',
    props: true,
    component: () => import('./views/TagPage.vue'),
  },
  {
    path: '/queue',
    name: 'queue',
    component: () => import('./views/QueuePage.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./views/ProfilePage.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('./views/SearchPage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('./views/AboutPage.vue'),
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from) {
    return {
      x: 0,
      y: from.name === to.name ? document.scrollingElement.scrollTop : 0,
    }
  },
})

export default router
