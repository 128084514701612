<template>
  <router-link
    :to="{ name: 'category', params: { id: category.id } }"
    class="tw-w-48 tw-text-xl tw-uppercase tw-font-bold tw-text-white tw-flex tw-items-center tw-justify-center tw-text-center tw-p-4 tw-aspect-video tw-select-none tw-cursor-pointer hyphens hover:tw-underline"
    :style="{ backgroundColor: color }"
  >
    {{ category.name }}
  </router-link>
</template>

<script lang="ts">
export default {
  DEFAULT_HEIGHT: 108,
  ITEM_KEY: 'category',
}
</script>

<script lang="ts" setup>
import randomColor from 'randomcolor'
import { computed, PropType } from 'vue'
import { Category } from '../../types'

const props = defineProps({
  category: { type: Object as PropType<Category>, required: true },
})
const color = computed(() =>
  randomColor({
    luminosity: 'dark',
    seed: props.category.name,
  }),
)
</script>
