<template>
  <div v-if="!paginator.isEmpty" class="paginator-nav">
    <template v-if="paginator.behavior === 'append'">
      <app-button v-if="paginator.hasNext" @click="paginator.next">{{
        $t('paginator_more')
      }}</app-button>
    </template>
    <template v-else-if="!paginator.useTouch">
      <app-button
        :disabled="!paginator.hasPrev"
        class="mr-3"
        :aria-label="$t('paginator_backward_label')"
        :size="isSmall ? 'small' : null"
        :style="{ visibility: paginator.hasPrev ? null : 'hidden' }"
        @click="paginator.prev"
      >
        <template #icon>
          <icon-feather-chevron-left v-if="isSmall" style="margin-left: -0.1em" />
        </template>
        <template v-if="!isSmall">{{ $t('paginator_backward') }}</template>
      </app-button>
      <app-button
        :disabled="!paginator.hasNext"
        :aria-label="$t('paginator_forward_label')"
        :size="isSmall ? 'small' : null"
        @click="paginator.next"
      >
        <template #icon>
          <icon-feather-chevron-right v-if="isSmall" style="margin-left: -0.1em" />
        </template>
        <template v-if="!isSmall">{{ $t('paginator_forward') }}</template>
      </app-button>
    </template>
  </div>
</template>

<script>
export default {
  inject: ['paginator'],
  computed: {
    isSmall() {
      return this.$mq.value === 'xs'
    },
  },
}
</script>

<style>
.paginator-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>

<i18n lang="yaml">
en:
  paginator_forward: Next
  paginator_forward_label: Next Page
  paginator_backward: Previous
  paginator_backward_label: Previous Page
  paginator_more: Show More

de:
  paginator_forward: Mehr
  paginator_forward_label: Nächste Seite
  paginator_backward: Zurück
  paginator_backward_label: Vorherige Seite
  paginator_more: Mehr anzeigen
</i18n>
