import { useWindowSize } from '@vueuse/core'
import { computed, createApp, ref } from 'vue'

type MQConfig = {
  breakpoints: Record<string, number>
}

const { width } = useWindowSize()
const config = ref<MQConfig>({ breakpoints: {} })

export const mq = computed(() => {
  for (const [key, maxWidth] of Object.entries(config.value.breakpoints)) {
    if (width.value < maxWidth) return key
  }
  return config.value.breakpoints[Object.keys(config.value.breakpoints)[0]]
})

export function useBreakpoint() {
  return mq
}

export default {
  install(app: ReturnType<typeof createApp>, options: MQConfig) {
    config.value = options
    app.config.globalProperties.$mq = mq
  },
}
