<template>
  <div
    v-show="isActive"
    :aria-live="isActive ? 'polite' : 'off'"
    role="alert"
    class="tw-absolute tw-z-0 tw-top-0 tw-left-0 tw-right-0 tw-shadow-inset tw-flex tw-items-center tw-justify-center"
    style="background-color: hsl(var(--color-base-base), calc(var(--color-base-lightness) + 5%))"
    :style="{ height: `${triggerDistance}px` }"
  >
    <div class="tw-flex tw-items-center tw-gap-3">
      <p
        v-if="!isRefreshing"
        class="tw-origin-center"
        :style="{ opacity: scale, transform: `scale(${scale})` }"
      >
        {{ t('refresh.pull') }}
      </p>
      <template v-else>
        <Spinner class="!tw-w-8 !tw-h-8" />
        <p>{{ t('refresh.updating') }}</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { usePullRefresh } from '../../util/dom'
import Spinner from './Spinner.vue'

const { t } = useI18n()
const { isRefreshing, isActive, triggerDistance, pulledPercentage } = usePullRefresh()
const scale = computed(() => pulledPercentage.value / 100)
</script>

<i18n lang="yaml">
en:
  refresh:
    pull: Pull to refresh
    updating: Updating content...

de:
  refresh:
    pull: Ziehen zum Aktualisieren
    updating: Aktualisiere Inhalte...
</i18n>
