import { createI18n } from 'vue-i18n'

const locales = import.meta.glob('./locales/*.json', { eager: true })

function detectLocale() {
  return (
    navigator.languages && Array.isArray(navigator.languages)
      ? navigator.languages[0]
      : navigator.language
  ).split('-')[0]
}

export default createI18n({
  allowComposition: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || detectLocale() || 'en',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: locales,
})
