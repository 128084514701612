<template>
  <button :type="type" class="button no-select" :class="cssClasses" @click="$emit('click', $event)">
    <slot name="icon" />
    <span v-if="$slots.default" class="button-label">
      <slot />
    </span>
    <span v-if="progressStyle" class="button-progress" :style="progressStyle"></span>
  </button>
</template>

<script>
import { oneOf } from '../../util'

export default {
  props: {
    type: {
      type: String,
      default: 'button',
      validator: oneOf(['button', 'submit']),
    },
    look: {
      type: String,
      default: null,
      validator: oneOf(['primary', 'transparent', 'livestream']),
    },
    size: {
      type: String,
      default: null,
      validator: oneOf(['small', 'medium', 'large']),
    },
    progress: {
      type: Number,
      default: null,
    },
  },
  computed: {
    cssClasses() {
      const classes = [this.size, this.look].filter((v) => v).map((v) => `is-${v}`)
      return classes.concat({
        'has-icon-only': this.$slots.icon && !this.$slots.default,
      })
    },
    progressStyle() {
      if (typeof this.progress === 'number' && this.progress >= 0 && this.progress < 100) {
        return { width: `${this.progress}%` }
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.button {
  display: inline-flex;
  border: none;
  background: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.5);
  color: hsl(var(--color-base-base), calc(var(--color-base-lightness) + 10%));
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: calc(0.375em - 1px) 0.75em;
  min-width: 5em;
  cursor: pointer;
  transition: none 66ms cubic-bezier(0.3, 0, 0, 1);
  transition-property: box-shadow, transform, background-color, opacity;
  transform-origin: 50% 50%;
  position: relative;
  font-size: 1rem;
  height: 2.25em;
  font-family: inherit;
  text-decoration: none;

  svg {
    display: block;
    height: 80%;
    width: auto;
  }

  &.has-icon-only {
    min-width: 0;
  }

  &:disabled {
    pointer-events: none;
    background: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.25);
  }

  &:hover,
  &:focus {
    background: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.75);
  }

  &:active {
    transform: scale(0.9) !important;
  }

  &.is-small {
    font-size: 0.85rem;
  }

  &.is-medium {
    font-size: 1.25rem;
  }

  &.is-large {
    font-size: 1.5rem;
  }

  &.is-primary {
    background-color: var(--color-primary);
    color: var(--color-foreground);

    &:hover,
    &:focus {
      background-color: hsl(var(--color-primary-base), calc(var(--color-primary-lightness) + 5%));
    }
  }

  &.is-livestream {
    background-color: var(--color-livestream-button-background);
    color: var(--color-livestream-button-foreground);

    &:hover,
    &:focus {
      background-color: hsl(
        var(--color-livestream-button-background-base),
        calc(var(--color-livestream-button-background-lightness) + 5%)
      );
    }
  }

  &.is-transparent {
    background: transparent;
    color: var(--color-foreground);

    &:hover,
    &:focus {
      background: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.1);
    }
  }
}

.button-label {
  svg + & {
    margin-left: 0.35em;
  }
}

.button-progress {
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
}
</style>
