<template>
  <div class="player">
    <PlayerDrawer v-if="isMinimal" :is-open="isDrawerOpen" @close="isDrawerOpen = false" />

    <div
      class="player-io no-select glass"
      :class="$player.currentTrack !== null && !isDrawerOpen ? 'is-active' : true"
    >
      <div v-if="isMinimal" class="player-level is-drawer">
        <PlayerDrawerIcon :is-open="isDrawerOpen" @click="isDrawerOpen = true" />
      </div>
      <div class="player-level is-track">
        <div>
          <PlayerTrackInfo :is-minimal="isMinimal" />
        </div>
      </div>
      <div class="player-level is-controls">
        <div>
          <PlayerControls :is-minimal="isMinimal" :class="isMinimal ? null : 'mb-3'" />
          <PlayerProgress :is-minimal="isMinimal" :class="isMinimal ? 'is-fixed-top' : null" />
        </div>
      </div>
      <div v-if="!isMinimal" class="player-level is-meta">
        <div>
          <PlayerExtraControls />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerControls from './PlayerControls.vue'
import PlayerDrawer from './PlayerDrawer.vue'
import PlayerDrawerIcon from './PlayerDrawerIcon.vue'
import PlayerExtraControls from './PlayerExtraControls.vue'
import PlayerProgress from './PlayerProgress.vue'
import PlayerTrackInfo from './PlayerTrackInfo.vue'

export default {
  components: {
    PlayerDrawerIcon,
    PlayerControls,
    PlayerDrawer,
    PlayerExtraControls,
    PlayerProgress,
    PlayerTrackInfo,
  },
  data() {
    return {
      isDrawerOpen: false,
    }
  },
  computed: {
    isMinimal() {
      return this.$mq.value === 'xs'
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/util';

.player-io {
  display: grid;
  grid-template-columns: [left] 1fr [center] 1.5fr [right] 1fr;
  grid-gap: 1.5rem;
  border: solid rgba(0, 0, 0, 0.75);
  border-width: 1px 0 0 0;
  height: 96px;
  padding: 0 1.5rem;
  transform: translateY(0);
  transition: transform 0.2s cubic-bezier(0.3, 0, 0, 1);

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .is-size-xs & {
    $size: 48px;
    border-width: 0 0 1px 0;
    grid-template-columns: [left] $size [center] auto [right] min-content;
    bottom: -#{$size};
    height: $size;
    grid-gap: 0;

    &.is-active {
      transform: translateY(calc(var(--screen-bottom-padding) * -1 - #{$size}));
    }
  }
}

.player-level {
  grid-row: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0;
  z-index: 2;
}

.player-level.is-drawer {
  grid-column: left;
  justify-content: start;
  z-index: 3;
}

.player-level.is-track {
  justify-content: flex-start;

  .is-size-xs & {
    grid-column: center;
    justify-content: center;
  }
}

.player-level.is-controls {
  > div {
    max-width: 650px;
    width: 100%;
  }

  .is-size-xs & {
    grid-column: right;
    margin-left: auto;
  }
}

.player-level.is-meta {
  justify-content: flex-end;
}
</style>
