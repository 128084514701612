import { useWindowSize } from '@vueuse/core'
import { computed, onUnmounted, readonly, ref, watch } from 'vue'
import { clampPercentage } from './number'
import { delay } from './promise'

type AnyCallback = () => void | Promise<void>

// TODO: move into usePullRefresh once the legacy pullRefreshController is removed
const pullRefreshSubscribers = ref<AnyCallback[]>([])

export const usePullRefresh = (function () {
  const TRIGGER_PULL_DISTANCE = 75

  const isRefreshing = ref(false)
  const hasSubscribers = computed(() => pullRefreshSubscribers.value.length > 0)
  const startY = ref(0)
  const pullDistance = ref(0)
  const pulledPercentage = clampPercentage(
    computed(() => (pullDistance.value / TRIGGER_PULL_DISTANCE) * 100),
  )
  const isPulling = ref(false)
  const isActive = computed(() => isPulling.value || isRefreshing.value)

  async function notifySubscribers() {
    isRefreshing.value = true

    try {
      const minimalWaitTime = delay(2000)
      const callbackCalls = pullRefreshSubscribers.value.map((cb) => cb())
      await Promise.all([minimalWaitTime, ...callbackCalls])
    } finally {
      isRefreshing.value = false
      reset()
    }
  }

  function onTouchStart(event: TouchEvent) {
    if (document.scrollingElement?.scrollTop === 0) {
      startY.value = event.touches[0].clientY
      isPulling.value = true
    }
  }

  async function onTouchMove(event: TouchEvent) {
    if (!isPulling.value || isRefreshing.value || pullRefreshSubscribers.value.length === 0) return
    const y = event.touches[0].clientY
    pullDistance.value = Math.min(TRIGGER_PULL_DISTANCE, Math.max(0, y - startY.value))
    if (pullDistance.value === TRIGGER_PULL_DISTANCE) {
      await notifySubscribers()
    }
  }

  function reset() {
    startY.value = 0
    isPulling.value = false
    if (!isRefreshing.value) {
      pullDistance.value = 0
    }
  }

  if (!import.meta.env.SSR) {
    watch(
      hasSubscribers,
      (hasSubscribers, hadSubscribers) => {
        if (hasSubscribers && !hadSubscribers) {
          document.body.addEventListener('touchstart', onTouchStart, { passive: true })
          document.body.addEventListener('touchmove', onTouchMove, { passive: true })
          document.body.addEventListener('touchend', reset, { passive: true })
          document.body.addEventListener('touchcancel', reset, { passive: true })
        } else if (!hasSubscribers && hadSubscribers) {
          document.body.removeEventListener('touchstart', onTouchStart)
          document.body.removeEventListener('touchmove', onTouchMove)
          document.body.removeEventListener('touchend', reset)
          document.body.removeEventListener('touchcancel', reset)
        }
      },
      { immediate: true },
    )
  }

  return function (listener?: AnyCallback) {
    if (listener) {
      pullRefreshSubscribers.value.push(listener)

      onUnmounted(() => {
        pullRefreshSubscribers.value.splice(pullRefreshSubscribers.value.indexOf(listener), 1)
      })
    }

    return {
      isRefreshing: readonly(isRefreshing),
      isPulling: readonly(isPulling),
      isActive,
      pullDistance: readonly(pullDistance),
      pulledPercentage,
      triggerDistance: TRIGGER_PULL_DISTANCE,
    }
  }
})()

// legacy interface for components using the Vue2 Options API
// TODO: remove once any use of pullRefreshController has been purged
export const pullRefreshController = {
  subscribe(subscriber: { refresh: AnyCallback }) {
    pullRefreshSubscribers.value.push(subscriber.refresh)
  },
  unsubscribe(subscriber: { refresh: AnyCallback }) {
    pullRefreshSubscribers.value.splice(pullRefreshSubscribers.value.indexOf(subscriber.refresh), 1)
  },
}

export function useWindowAspectRatio() {
  const { width, height } = useWindowSize()
  return width.value / height.value
}
