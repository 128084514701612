<template>
  <time :datetime="date.toISOString()">{{ label }}</time>
</template>

<script>
import { useNow } from '@vueuse/core'
import { differenceInCalendarDays, format, formatDistance, formatRelative } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'

const localeMap = {
  de,
  en,
}

const now = useNow()

export default {
  props: {
    date: {
      type: Date,
      required: true,
    },
    reference: {
      type: Date,
      default: null,
    },
    invert: Boolean,
    exactOptions: {
      type: Object,
      default: () => ({
        dateStyle: 'long',
        timeStyle: 'short',
      }),
    },
    useExact: Boolean,
    useRelative: Boolean,
    useDistance: Boolean,
    useAutoDistance: Boolean,
  },
  computed: {
    label() {
      const _reference = this.reference || now.value
      const [reference, date] = this.invert ? [this.date, _reference] : [_reference, this.date]
      const opts = { locale: this.locale, addSuffix: true }

      if (this.useRelative) {
        return formatRelative(date, reference, opts)
      } else if (this.useDistance) {
        return formatDistance(date, reference, opts)
      } else if (this.useAutoDistance) {
        return Math.abs(differenceInCalendarDays(date, reference)) > 7
          ? formatRelative(date, reference, opts)
          : formatDistance(date, reference, opts)
      } else if (this.useExact) {
        return date.toLocaleString(this.$i18n.locale, this.exactOptions)
      } else {
        return format(date, opts)
      }
    },
    locale() {
      return localeMap[this.$i18n.locale] || en
    },
  },
}
</script>
