import Vue from 'vue'

import TextClamp from 'vue3-text-clamp'

import AutoGrid from './generic/AutoGrid.vue'
import Button from './generic/Button.vue'
import Card from './generic/Card.vue'
import CategoryPath from './generic/CategoryPath.vue'
import Container from './generic/Container.vue'
import Download from './generic/Download.vue'
import Field from './generic/Field.vue'
import Header from './generic/Header.vue'
import Image from './generic/Image.vue'
import Input from './generic/Input.vue'
import Like from './generic/Like.vue'
import Markdown from './generic/Markdown'
import Page from './generic/Page.vue'
import Paginator from './generic/Paginator.vue'
import PaginatorNav from './generic/PaginatorNav.vue'
import Person from './generic/Person.vue'
import Section from './generic/Section.vue'
import Share from './generic/Share.vue'
import Spinner from './generic/Spinner.vue'
import SpinnerArea from './generic/SpinnerArea.vue'
import Tag from './generic/Tag.vue'
import Time from './generic/Time.vue'
import CategoryPreview from './model/CategoryPreview.vue'
import RecordingPreviewAlt from './model/RecordingPreviewAlt.vue'
import SeriesPreview from './model/SeriesPreview.vue'
import PlayPause from './player/PlayPause.vue'

Vue.component('AppAutoGrid', AutoGrid)
Vue.component('AppButton', Button)
Vue.component('AppCard', Card)
Vue.component('AppCategoryPath', CategoryPath)
Vue.component('AppClamp', TextClamp)
Vue.component('AppContainer', Container)
Vue.component('AppDownload', Download)
Vue.component('AppField', Field)
Vue.component('AppHeader', Header)
Vue.component('AppImage', Image)
Vue.component('AppInput', Input)
Vue.component('AppLike', Like)
Vue.component('AppMarkdown', Markdown)
Vue.component('AppPage', Page)
Vue.component('AppPaginator', Paginator)
Vue.component('AppPaginatorNav', PaginatorNav)
Vue.component('AppPlayPause', PlayPause)
Vue.component('AppSection', Section)
Vue.component('AppShare', Share)
Vue.component('AppSpinner', Spinner)
Vue.component('AppSpinnerArea', SpinnerArea)
Vue.component('AppTag', Tag)
Vue.component('AppTime', Time)
Vue.component('AppPerson', Person)
Vue.component('AppCategoryPreview', CategoryPreview)
Vue.component('AppRecordingPreviewAlt', RecordingPreviewAlt)
Vue.component('AppSeriesPreview', SeriesPreview)
