import {
  computed,
  ComputedRef,
  reactive,
  UnwrapNestedRefs,
  UnwrapRef,
  watch,
  WritableComputedRef,
} from 'vue'

type Obj = Record<string, any>
type Getter<T extends Obj, V> = (obj: T) => V
type Setter<T extends Obj, V> = (obj: T, value: V) => void
type State<T> = UnwrapNestedRefs<{ state: T; initState: T }>
type Mapper<T extends Obj, V> =
  | string
  | Getter<T, V>
  | [Getter<T, V>]
  | [Getter<T, V>, Setter<T, V>]
type Store = {
  get<V>(key: string, defaultValue: V): Promise<V>
  set<V>(key: string, value: V): void
}

function createPathMapper<T extends Obj, V>(path: string): [Getter<T, V>, Setter<T, V>] {
  const keysToProp = path.split('.')
  const prop = keysToProp.pop() as keyof T
  function traverse(obj: T, props: string[]): T {
    const [key, ...remainingProps] = props
    return remainingProps.length === 0 ? obj[key] : traverse(obj[key], remainingProps)
  }

  return [
    function (obj: T): V {
      return traverse(obj, keysToProp)[prop]
    },
    function (obj: T, newValue: V): void {
      traverse(obj, keysToProp)[prop] = newValue as any
    },
  ]
}

function normalizeMapper<T extends Obj, V>(mapper: Mapper<T, V>): [Getter<T, V>, Setter<T, V>?] {
  if (typeof mapper === 'string') {
    return createPathMapper<T, V>(mapper)
  } else if (typeof mapper === 'function') {
    return [mapper, undefined]
  } else if (Array.isArray(mapper) && (mapper.length === 1 || mapper.length === 2)) {
    return mapper
  } else {
    throw new Error('invalid mapper type')
  }
}

function bindMapper<T extends Obj, V>(state: T, mapper: string): WritableComputedRef<V>
function bindMapper<T extends Obj, V>(state: T, mapper: Getter<T, V>): ComputedRef<V>
function bindMapper<T extends Obj, V>(state: T, mapper: [Getter<T, V>]): ComputedRef<V>
function bindMapper<T extends Obj, V>(
  state: T,
  mapper: [Getter<T, V>, Setter<T, V>],
): WritableComputedRef<V>
function bindMapper<T extends Obj, V>(state: T, mapper: Mapper<T, V>) {
  const [getter, setter] = normalizeMapper<T, V>(mapper)
  const get = () => getter(state)

  return !setter
    ? computed(get)
    : computed({
        get,
        set(newValue: V) {
          setter(state, newValue)
        },
      })
}

function mapState<T extends Obj, V>(state: T, objMappers: Record<string, string>) {
  return Object.fromEntries(
    Object.entries(objMappers).map(([key, mapper]) => [key, bindMapper<T, V>(state, mapper)]),
  )
}

export function createStore<T extends Obj>(state: T, hooks: ((state: State<T>) => void)[]) {
  const initState = Object.assign({}, state)
  const internalState = reactive({
    state,
    initState,
  })

  for (const hook of hooks) {
    hook(internalState)
  }

  // TODO: fix typescript errors
  return {
    mapRef<V>(mapper: Mapper<T, V>) {
      // @ts-expect-error mapper should but does not match bindMapper overload requirements
      return bindMapper<UnwrapRef<T>, V>(internalState.state, mapper)
    },
    mapState<V>(objMappers: Record<string, Mapper<T, V>>) {
      // @ts-expect-error mapper should but does not match bindMapper overload requirements
      return mapState<UnwrapRef<Obj>, V>(internalState.state, objMappers)
    },
  }
}

export function sync<T extends Obj, V>(store: Store, propertyPath: string) {
  return (data: State<T>) => {
    const [getter, setter] = createPathMapper<UnwrapRef<T>, V>(propertyPath)
    watch(
      () => getter(data.state),
      (newValue) => {
        store.set(propertyPath, newValue)
      },
      { deep: true },
    )
    store.get(propertyPath, getter(data.initState)).then((value) => {
      setter(data.state, value)
    })
  }
}
