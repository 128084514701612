<template>
  <div v-if="realSource" ref="container" class="image br-1" :class="cssClasses" :style="style">
    <img
      :src="realSource"
      :alt="alt"
      v-bind="{ ...dimensions, ...$attrs }"
      crossorigin="anonymous"
      :style="{ backgroundImage: fallbackImageURL ? `url(${fallbackImageURL})` : undefined }"
      @load="updateLuminosity"
    />
  </div>
</template>

<script>
import mem from 'mem'
import supportsWebP from 'supports-webp'
import defaultFallbackImage from '../../assets/placeholder.png'
import { clamp, createQueryString } from '../../util'
import { PersistentMap } from '../../util/persistance'

const instances = {}
let instanceId = 0
let hasWebPSupport = false

window.addEventListener('resize', () => {
  Object.values(instances).forEach((instance) => instance.updateWidth())
})

supportsWebP.then((isSupported) => {
  hasWebPSupport = isSupported
})

const calculateImageLuminosity = mem(
  (img) => {
    let colorSum = 0
    const { width, height } = img

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    const data = ctx.getImageData(0, 0, width, height).data

    for (let i = 0; i < data.length; i += 4) {
      const [r, g, b] = data.slice(i, i + 3)
      colorSum += Math.floor((r + g + b) / 3)
    }
    return colorSum / (width * height) / 255
  },
  { cache: new PersistentMap('imageLuminosity'), cacheKey: (img) => img.src },
)

export default {
  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    size: Number,
    fallbackTransparent: Boolean,
    fallbackSrc: String,
    useDefaultFallback: Boolean,
    autoSize: Boolean,
    contain: Boolean,
    cover: Boolean,
    coverScreen: Boolean,
    calculateLuminosity: Boolean,
  },
  data() {
    return {
      width: null,
      pixelRatio: null,
      id: instanceId++,
      luminosity: null,
    }
  },
  computed: {
    cssClasses() {
      return {
        'is-contained': this.contain,
        'is-covering': this.cover,
        'is-covering-screen': this.coverScreen,
        'is-fixed-size': this.size,
      }
    },
    style() {
      const result = {}
      const size = this.size || this.width
      if (size && this.aspectRatio !== null) {
        result.width = `${size}px`
        result.height = `${size / this.aspectRatio}px`
      }
      if (this.luminosity) {
        result['--luminosity'] = clamp(this.luminosity, 0.5, 0.8)
      }
      return result
    },
    fallbackImageURL() {
      if (this.fallbackTransparent) {
        return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      }

      if (this.fallbackSrc) {
        return this.fallbackSrc
      }

      if (this.useDefaultFallback) {
        return defaultFallbackImage
      }

      return null
    },
    realSource() {
      if (this.src) {
        return this.autoSize ? this.createAutoSizeUrl(this.size) : this.src
      } else {
        return this.fallbackImageURL
      }
    },
    dimensions() {
      const size = this.size || this.width
      const aspectRatio = this.aspectRatio
      if (size && aspectRatio) {
        return {
          width: size,
          height: size / aspectRatio,
        }
      } else {
        return {}
      }
    },
  },
  mounted() {
    this.updateWidth()
    instances[this.id] = this
  },
  unmounted() {
    delete instances[this.id]
  },
  methods: {
    createAutoSizeUrl(size) {
      const baseSize = size || this.width
      const params = {
        width: parseInt(baseSize),
        height: parseInt(baseSize / this.aspectRatio),
      }
      if (hasWebPSupport) {
        params['format'] = 'WEBP'
      }
      return `${this.src}?${createQueryString(params)}`
    },
    updateLuminosity(event) {
      if (this.calculateImageLuminosity) {
        this.luminosity = calculateImageLuminosity(event.target)
      }
    },
    updateWidth() {
      if (this.$refs.container) {
        this.width = this.$refs.container.getBoundingClientRect().width
      }
      this.pixelRatio = window.devicePixelRatio || 1
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.image {
  overflow: hidden;
}

.image img {
  max-width: 100%;
  display: block;
  background-size: cover;
}

.image.is-fixed-size img {
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.image.is-contained img {
  object-fit: contain;
}

.image.is-covering img {
  object-fit: cover;
}

.image.is-covering-screen {
  --luminosity: 0.5;
  width: 100% !important;
  height: 75vh !important;
  overflow: hidden;
  pointer-events: none;
  filter: blur(10px);
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    max-height: none !important;
    height: auto !important;
    transform: translateY(-10%);
    filter: brightness(calc(1 - var(--luminosity)));
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 100%;
    background-image: linear-gradient(
      hsla(var(--color-base-base), var(--color-base-lightness), 0),
      var(--color-base)
    );
    z-index: 2;
  }
}
</style>
