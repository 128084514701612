<template>
  <icon-feather-volume v-if="index === 0" />
  <icon-feather-volume-1 v-else-if="index === 1" />
  <icon-feather-volume-2 v-else-if="index === 2" />
</template>

<script lang="ts" setup>
import { useInterval } from '@vueuse/core'
import { computed } from 'vue'

const counter = useInterval(1000)
const index = computed(() => counter.value % 3)
</script>
