<template>
  <div v-if="!isEmpty || !hideEmpty" class="paginator" :class="`is-${behavior}`">
    <slot
      name="header"
      :items="items"
      :count="count"
      :is-loading="isLoading"
      :has-next="hasNext"
      :has-prev="hasPrev"
    />

    <div
      ref="items"
      class="paginator-items"
      v-bind="$attrs"
      :style="isLoading && currentItemsHeight ? { height: `${currentItemsHeight}px` } : null"
      @touchstart.passive="$emit('touchnav-start', $event)"
      @touchmove.passive="$emit('touchnav-move', $event)"
      @touchend.passive="$emit('touchnav-end', $event)"
    >
      <slot
        :items="items"
        :count="count"
        :is-loading="isLoading"
        :has-next="hasNext"
        :has-prev="hasPrev"
      ></slot>
      <app-spinner-area v-if="!noLoader && isLoading && !useTouch" />
      <PaginatorTouchNav v-if="useTouch" ref="touchNav" />
      <slot v-if="emptyMessage" name="empty-items">
        <div v-if="isEmpty && !isLoading" class="content">
          <p>{{ emptyMessage }}</p>
        </div>
      </slot>
      <PageIndicator />
    </div>

    <slot
      name="footer"
      :items="items"
      :count="count"
      :is-loading="isLoading"
      :has-next="hasNext"
      :has-prev="hasPrev"
    />
  </div>
</template>

<script>
import { createQueryString } from '../../util'
import { pullRefreshController } from '../../util/dom'
import { decodeHash, manipulateHash } from '../../util/encoder'
import PageIndicator from './PageIndicator.vue'
import PaginatorTouchNav from './PaginatorTouchNav.vue'

const { matchMedia } = window

const getQueryString = (url) => (url ? new URL(url).search.substring(1) : null)

export default {
  components: { PageIndicator, PaginatorTouchNav },
  provide() {
    return {
      paginator: this,
    }
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
    behavior: {
      type: String,
      default: 'replace',
      validator: function (value) {
        return ['replace', 'append'].indexOf(value) !== -1
      },
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageLimit: {
      type: Number,
      default: null,
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    id: {
      type: String,
      default: null,
    },
    noLoader: Boolean,
  },
  data() {
    return {
      items: [],
      count: null,
      nextUrl: null,
      prevUrl: null,
      isLoading: false,
      currentItemsHeight: null,
      abortController: null,
      page: 1,
      currentRequest: null,
      useTouch: matchMedia('(pointer: coarse)').matches,
    }
  },
  computed: {
    hasNext() {
      if (this.nextUrl === null) return false
      if (this.pageLimit) return this.page < this.pageLimit
      return true
    },
    hasPrev() {
      return this.prevUrl !== null
    },
    allFilters() {
      // this should include any filter that controls the number
      // of items in a collection by ex- or inclusion
      return { ...this.filters, pageSize: this.pageSize }
    },
    currentQueryString() {
      return createQueryString({ ...this.allFilters, page: this.page })
    },
    isEmpty() {
      return this.count === 0
    },
    numberOfPages() {
      return Math.min(
        this.pageLimit || Number.POSITIVE_INFINITY,
        Math.ceil(this.count / this.pageSize),
      )
    },
  },
  watch: {
    allFilters: {
      deep: true,
      handler() {
        this.reset()
      },
    },
  },
  created() {
    const hashData = decodeHash(this.$route.hash.substring(1))
    this.reset(this.id && hashData[this.id] ? parseInt(hashData[this.id]) : null)
    pullRefreshController.subscribe(this)
  },
  beforeUnmount() {
    pullRefreshController.unsubscribe(this)
  },
  methods: {
    updateFrom(query, { forceReplace = false, wait = Promise.resolve() }) {
      const abortController = new AbortController()
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = abortController
      this.currentItemsHeight = this.$refs.items
        ? this.$refs.items.getBoundingClientRect().height
        : this.minHeight || null
      this.isLoading = true
      this.currentRequest = this.collection
        .list(query, { signal: abortController.signal })
        .then(async (data) => {
          await wait
          return data
        })
        .then(
          (data) => {
            this.items =
              forceReplace || this.behavior === 'replace'
                ? data.results
                : [].concat(this.items, data.results)
            this.count = data.count
            this.nextUrl = getQueryString(data.next)
            this.prevUrl = getQueryString(data.previous)
          },
          (err) => {
            // TODO: add user notification for error
            if (process.env.NODE_ENV === 'development') {
              window.console.error(`could not load data for query "${query}"`, err)
            }
          },
        )
        .then(this.savePageInUrl)
        .finally(() => {
          this.isLoading = false
          this.abortController = null
          this.currentRequest = null
        })
      return this.currentRequest
    },
    savePageInUrl() {
      if (this.id) {
        const path = this.$route.fullPath.split('#')[0]
        const hash = manipulateHash(this.$route.hash.substring(1), { [this.id]: this.page })
        const newFullPath = `${path}#${hash}`
        if (this.$route.fullPath !== newFullPath) {
          this.$router.replace(newFullPath)
        }
      }
    },
    reset(page = 1) {
      this.items = []
      this.count = this.nextUrl = this.prevUrl = null
      this.page = typeof page === 'number' ? Math.max(1, page) : 1
      return this.updateFrom(this.currentQueryString, { forceReplace: true })
    },
    refresh() {
      this.collection.clearCache()
      return this.reset()
    },
    prev(options) {
      if (this.hasPrev) {
        this.page -= 1
        return this.updateFrom(this.currentQueryString, options)
      }
    },
    next(options) {
      if (this.hasNext) {
        this.page += 1
        return this.updateFrom(this.currentQueryString, options)
      }
    },
  },
}
</script>

<style>
.paginator-items {
  position: relative;
  overflow: hidden;
  overscroll-behavior-x: contain;
}
</style>
