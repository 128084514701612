<template>
  <app-card
    is-vertical
    :title="recording.title"
    :description="recording.description"
    :link="{ to: { name: 'recording', params: { id: recording.id } } }"
    has-body-link
    show-full-title
    :max-description-lines="4"
    class="tw-w-64"
  >
    <template #image>
      <app-image
        :aspect-ratio="16 / 9"
        :size="300"
        auto-size
        :src="image?.url ?? null"
        :fallback-src="env.fallbackSeriesCover"
        cover
      />
    </template>
  </app-card>
</template>

<script lang="ts">
export default {
  DEFAULT_HEIGHT: 288,
  ITEM_KEY: 'recording',
}
</script>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useEnv, useImage } from '../../app'
import { Recording } from '../../types'

const props = defineProps({
  recording: { type: Object as PropType<Recording>, required: true },
})
const image = useImage(computed(() => props.recording.imageId))
const env = useEnv()
</script>
