<template>
  <div class="person">
    <div class="roles">{{ rolesText }}</div>
    <div class="name">
      {{ person.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: Object,
    roles: {
      required: true,
      type: Array,
    },
  },
  computed: {
    rolesText() {
      if (this.roles.length === 0) {
        return this.$t('person_role_default')
      }

      return this.roles.map((r) => this.mapRole(r)).join(', ')
    },
  },
  methods: {
    mapRole(role) {
      const i18n = this.$t(`person_role_${role}`)
      if (i18n === role) {
        // No translation found for this role. Display role id.
        return role
      }

      return i18n
    },
  },
}
</script>

<i18n lang="yaml">
en:
  person_role_default: Participation
  person_role_host: Host
  person_role_news: News
  person_role_editor: Editor
  person_role_chief: Chief Editor
  person_role_interviewer: Interview
  person_role_cutter: Cut
  person_role_voice: Voice
  person_role_translator: Translation

de:
  person_role_default: Mitwirkung
  person_role_host: Moderation
  person_role_news: Nachrichten
  person_role_editor: Redaktion
  person_role_chief: Redakteur*in vom Dienst
  person_role_interviewer: Interview
  person_role_cutter: Schnitt
  person_role_voice: Stimme
  person_role_translator: Übersetzung
</i18n>

<style lang="scss" scoped>
.person {
  border-radius: 2px;
  background-color: hsla(var(--color-base-base), calc(var(--color-base-lightness) + 2.5%), 0.65);
  padding: 0.5rem;
}

.roles {
  color: var(--color-foreground-dimmed);
  margin-bottom: 0.25rem;
}

.name {
  font-size: 1.2em;
}
</style>
