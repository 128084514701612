<template>
  <router-link
    :to="{ name: 'tag', params: { slug: tag.slug } }"
    class="tag color-fg"
    :title="tag.name"
    :style="{ 'font-size': fontSize }"
  >
    <span>{{ tag.name }}</span>
    <app-image
      v-if="tag.icon"
      :size="20"
      :aspect-ratio="1"
      :src="tag.icon"
      auto-size
      class="ml-1"
    />
  </router-link>
</template>

<script>
import { mapValueToDomain } from '../../util/number'

export default {
  props: {
    tag: { type: Object, required: true },
    grow: Boolean,
    maxWeight: { type: Number, default: undefined },
  },
  computed: {
    fontSize() {
      const maxWeight = this.maxWeight ?? this.tag.weight
      const sizeFactor = mapValueToDomain(this.tag.weight, [1, maxWeight], [1, 1.5])
      return this.grow ? `calc(1rem * ${sizeFactor})` : null
    },
  },
}
</script>

<style lang="scss">
.tag {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 0.25rem;
  border-radius: 2px;
  background-color: hsla(var(--color-base-base), calc(var(--color-base-lightness) + 10%), 0.65);

  .image {
    border-radius: 50%;
  }
}
</style>
