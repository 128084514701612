<template>
  <div
    ref="el"
    class="progress"
    :class="[`is-${look}`, { 'is-dragging': isDragging }]"
    role="slider"
    tabindex="0"
    :aria-valuemin="min"
    :aria-valuemax="max"
    :aria-valuenow="modelValue"
    :aria-valuetext="label"
    @click="seekFromMousePosition"
    @keyup.home.exact="seek(0)"
    @keydown.home.exact.prevent
    @keyup.end.exact="seek(max)"
    @keydown.end.exact.prevent
    @keyup.up.exact="seekBy(step)"
    @keydown.up.exact.prevent
    @keyup.down.exact="seekBy(-step)"
    @keydown.down.exact.prevent
    @keyup.page-up.exact="seekBy(step * stepFactor)"
    @keydown.page-up.exact.prevent
    @keyup.page-down.exact="seekBy(-step * stepFactor)"
    @keydown.page-down.exact.prevent
  >
    <div class="progress-track" role="presentation">
      <div
        class="progress-value"
        :style="{ transform: `translateX(${(progress / max) * 100}%)` }"
      ></div>
    </div>
    <button
      v-if="editable"
      ref="knob"
      type="button"
      class="progress-knob"
      :style="{ transform: `translateX(calc(${width * (progress / max)}px + 50%))` }"
      :aria-label="knobLabel"
    ></button>
  </div>
</template>

<script lang="ts" setup>
// @ts-ignore
import { useElementBounding, useMouse, useMousePressed } from '@vueuse/core'
import { computed, PropType, ref, watch, watchEffect } from 'vue'
import { oneOf } from '../util'

const props = defineProps({
  modelValue: { type: Number as PropType<number>, required: true },
  min: { type: Number as PropType<number>, default: 0 },
  max: { type: Number as PropType<number>, default: 100 },
  step: { type: Number as PropType<number>, default: 1 },
  stepFactor: { type: Number as PropType<number>, default: 10 },
  editable: { type: Boolean as PropType<boolean>, default: true },
  look: {
    type: String as PropType<'default' | 'plain'>,
    default: 'default',
    validator: oneOf(['default', 'plain']),
  },
  label: { type: String as PropType<string>, default: '' },
  knobLabel: { type: String as PropType<string>, default: '' },
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
  (e: 'peek', value: null | number): void
}>()

const { x: mouseX } = useMouse()
const el = ref<HTMLDivElement>()
const knob = ref<HTMLButtonElement>()
const { width, left } = useElementBounding(el)
const { pressed: isDragging } = useMousePressed({ target: knob, initialValue: false })
const dragProgress = computed<number>(() => {
  const { min, max } = props
  const right = left.value + width.value
  return mouseX.value < left.value
    ? min
    : mouseX.value > right
    ? max
    : ((mouseX.value - left.value) / width.value) * max
})
const progress = computed<number>(() => {
  if (!isDragging.value) {
    return props.modelValue
  } else {
    return dragProgress.value
  }
})

function seekFromMousePosition(event: MouseEvent) {
  if (!props.editable) return
  const relativeValue = (event.clientX - left.value) / width.value
  emit('update:modelValue', relativeValue * props.max)
}

function seek(value: number) {
  if (!props.editable) return
  emit('update:modelValue', value)
}

function seekBy(value: number) {
  if (!props.editable) return
  emit('update:modelValue', Math.min(props.max, Math.max(props.min, props.modelValue + value)))
}

watch(isDragging, (isDragging, wasDragging) => {
  if (!isDragging && wasDragging) {
    emit('peek', null)
    if (props.editable) {
      emit('update:modelValue', dragProgress.value)
    }
  }
})

watchEffect(() => {
  if (isDragging.value) {
    emit('peek', progress.value)
  }
})
</script>

<style lang="scss">
.progress {
  width: 100%;
  padding: 6px 0;
  position: relative;
  overflow: visible;

  &.is-plain {
    padding: 0;
  }
}

.progress-track {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-muted);
  overflow: hidden;
  position: relative;

  .progress.is-plain & {
    height: 2px;
    border-radius: 0;
  }
}

.progress-value {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 100%;
  transition: color 0.1s cubic-bezier(0.3, 0, 0, 1);
  transition-delay: 0.1s;
  background-color: currentColor;
  color: var(--color-foreground-dimmed);
  z-index: 1;
  border-radius: 2px;
}

.progress:hover .progress-value,
.progress.is-dragging .progress-value {
  color: var(--color-accent);
}

.progress-knob {
  border-radius: 50%;
  background-color: var(--color-foreground);
  width: 12px;
  height: 12px;
  border: none;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: calc(50% - 6px);
  transition: opacity 0.1s cubic-bezier(0.3, 0, 0, 1);
  transition-delay: 0.1s;
  z-index: 2;
}

.progress-knob::after {
  $size: 5px;
  content: '';
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -$size;
  right: -$size;
  top: -$size;
  bottom: -$size;
  cursor: pointer;
  transform: scale(0);
  transform-origin: center;
  transition: transform 0.1s;
}

.progress:hover .progress-knob,
.progress.is-dragging .progress-knob {
  opacity: 1;
  pointer-events: all;
}

.progress.is-dragging .progress-knob::after {
  transform: scale(1);
}
</style>
