<template>
  <app-card
    v-if="trackData"
    :link="link"
    :image="{ useDefaultFallback: true, size: isVertical ? null : 64 }"
    :is-vertical="isVertical"
    :title="trackData.title"
    :description="trackData.description"
    v-bind="$attrs"
  >
  </app-card>
</template>

<script>
export default {
  props: {
    isVertical: Boolean,
  },
  computed: {
    trackData() {
      return this.$player.currentTrack ? this.$player.currentTrack.metadata : null
    },
    link() {
      return this.trackData && typeof this.trackData.collectionId === 'number'
        ? { to: { name: 'recording', params: { id: this.trackData.collectionId } } }
        : null
    },
  },
}
</script>
