import { get, set, Store } from 'idb-keyval'

const { localStorage } = window

export class PersistentMap {
  constructor(name) {
    this.store = name
  }
  key(key) {
    return `${this.store}::${key}`
  }
  get(key) {
    const data = localStorage.getItem(this.key(key))
    return data === null ? null : JSON.parse(data)
  }
  set(key, value) {
    const data = JSON.stringify(value)
    localStorage.setItem(this.key(key), data)
    return this
  }
  clear() {
    const prefix = this.key('')
    const keysToRemove = []
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith(prefix)) {
        keysToRemove.push(key)
      }
    }
    for (const key of keysToRemove) {
      localStorage.removeItem(key)
    }
    return this
  }
  has(key) {
    return localStorage.getItem(this.key(key)) !== null
  }
  delete(key) {
    localStorage.removeItem(this.key(key))
    return this
  }
}

export class IDBStore {
  constructor(name) {
    this.store = new Store(name)
  }

  async get(key, defaultValue) {
    const value = await get(key, this.store)
    return typeof value === 'undefined' ? defaultValue : value
  }

  set(key, value) {
    return set(key, value, this.store)
  }
}
