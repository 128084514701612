<template>
  <button
    type="button"
    class="play no-select"
    :class="isCurrentTrack && 'is-playing'"
    :aria-label="$t(isCurrentTrack ? 'play_pause_track' : 'play_play_track', [title])"
    @click="togglePlay"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <app-image
      class="bg-surface"
      :src="isCurrentTrack ? null : image"
      calculate-luminosity
      fallback-transparent
      :aspect-ratio="1"
      cover
      :size="size"
    ></app-image>
    <span class="play-icon">
      <component :is="icon" :style="iconStyle" />
    </span>
  </button>
</template>

<script>
import { playPauseMixin } from './mixins'

export default {
  mixins: [playPauseMixin],
  props: {
    title: String,
    image: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 72,
    },
  },
  data() {
    return {
      circleIcons: false,
    }
  },
  computed: {
    iconStyle() {
      const style = { strokeWidth: 1 }
      if (this.currentAction === 'play') {
        const size = `${this.size / 2}px`
        style['width'] = size
        style['height'] = size
        style['fill'] = 'var(--color-foreground)'
      } else {
        const size = `${this.size / 1.5}px`
        style['width'] = size
        style['height'] = size
      }
      return style
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.play {
  position: relative;
  cursor: pointer;
  border: none;
  background: none;
  color: var(--color-foreground);
  padding: 0;
  align-self: flex-start;
  --luminosity: 0.5;

  .image {
    filter: brightness(calc(1.25 - var(--luminosity)));
  }

  &:hover,
  &:focus-visible {
    .play-icon svg {
      transform: scale(1.06);
    }
  }

  &:active {
    .play-icon svg {
      transform: scale(0.9) !important;
    }
  }

  &.is-playing {
    color: var(--color-primary);

    .image {
      filter: none;
    }
  }
}

.play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  background-color: hsla(var(--color-base-base), calc(var(--color-base-lightness) + 2.5%), 0.65);
  backdrop-filter: grayscale(35%);

  svg {
    transition: none 33ms cubic-bezier(0.3, 0, 0, 1);
    transition-property: transform;
    transform-origin: 50% 50%;
  }
}

.play .image {
  transition: 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition-property: filter;
}
</style>

<i18n lang="yaml">
en:
  play_play_track: Play '{0}'
  play_pause_track: Pause track

de:
  play_play_track: Spiele '{0}' ab
  play_pause_track: Aktuellen Titel pausieren
</i18n>
