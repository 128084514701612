import mem from 'mem'

const { Map } = window

const globalCache = new Map()
const createCache = (ttl) => {
  const fetcher = async (url) => {
    const res = await fetch(url)
    if (res.status === 404) {
      return null
    } else {
      return await res.json()
    }
  }
  return mem(fetcher, {
    cache: globalCache,
    cachePromiseRejection: false,
    maxAge: ttl * 1000,
  })
}

class Collection {
  constructor(path, cache) {
    this.basePath = path
    this.cache = cache
  }
  list(filterQuery, options) {
    return this.cache(`${this.basePath}/?${filterQuery}`, options)
  }
  get(id) {
    return this.cache(`${this.basePath}/${id}/`)
  }
  clearCache() {
    mem.clear(this.cache)
  }
}

export default function createDataSources(env) {
  const createUrl = (subPath) => `${env.apiPath}${subPath}`

  const createImageUrl = (imageId) => {
    if (imageId === null) return null
    return createUrl(`/images/${imageId}/render/`)
  }

  const broadcasts = new Collection(createUrl(`/broadcasts`), createCache(300))
  const recordings = new Collection(createUrl(`/recordings`), createCache(120))
  const categories = new Collection(createUrl(`/categories`), createCache(600))
  const series = new Collection(createUrl(`/series`), createCache(600))
  const tags = new Collection(createUrl('/tags'), createCache(300))

  return {
    createImageUrl,
    broadcasts,
    recordings,
    categories,
    series,
    tags,
  }
}
