export function decodeHash(str) {
  return str
    .split('&')
    .map((pair) => pair.split('='))
    .reduce((result, [key, value]) => {
      if (key) {
        result[key] = decodeURIComponent(value)
      }
      return result
    }, {})
}

export function encodeHash(data) {
  return Object.entries(data)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
}

export function manipulateHash(str, newOrUpdatedData) {
  return encodeHash({
    ...decodeHash(str),
    ...newOrUpdatedData,
  })
}
