// migration plugin for vue-window-size
import { useWindowSize } from '@vueuse/core'
import { createApp, Ref } from 'vue'

const { width, height } = useWindowSize()

export default {
  install(app: ReturnType<typeof createApp>) {
    app.config.globalProperties.$windowWidth = width
    app.config.globalProperties.$windowHeight = height
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $windowWidth: Ref<number>
    $windowHeight: Ref<number>
  }
}
