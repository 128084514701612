<template>
  <AppButton v-bind="$attrs" :title="label" :aria-label="label" @click.prevent="downloadAllFiles">
    <template #icon>
      <icon-feather-download />
    </template>
    <slot />
  </AppButton>
</template>

<script>
import multiDownload from 'multi-download'

export default {
  props: {
    name: String,
    files: {
      type: [String, Array],
      required: true,
    },
  },
  computed: {
    label() {
      return this.$t(this.name ? 'download_label' : 'download_label_default', [this.name])
    },
  },
  methods: {
    downloadAllFiles() {
      multiDownload(Array.isArray(this.files) ? this.files : [this.files])
    },
  },
}
</script>

<i18n lang="yaml">
en:
  download_label: 'Download {0}'
  download_label_default: Download file

de:
  download_label: '{0} herunterladen'
  download_label_default: Datei herunterladen
</i18n>
