import { createApp } from 'vue'
import App from './App.vue'
import { loadEnvironment } from './bootstrap'
import createDataSources from './data'
import { errorToHtml } from './error'
import i18n from './i18n'
import mq from './mq'
import Player from './player'
import router from './router'
import windowSize from './window-size'

import './styles/main.scss'

import { registerDataSources, registerEnv } from './app'
import './components/register'
import { registerPWA, registerServiceWorker } from './util/pwa'

registerServiceWorker()

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(windowSize)
app.use(mq, {
  // These breakpoints are documented in the thekno environment file (environment-configuration.md).
  // Please update them if you update, remove, or change any of the breakpoint names.
  breakpoints: {
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1904,
    xl: Infinity,
  },
})

function init(env) {
  const dataSources = createDataSources(env)
  registerEnv(env)
  registerPWA(env)
  registerDataSources(dataSources)
  app.use(Player, { env })
  app.provide('dataSources', dataSources)
  app.provide('env', env)
  app.mount('#app')
}

loadEnvironment().then(init, (e) => {
  document.querySelector('#app').innerHTML = errorToHtml(e, 'Could not initialize thekno app')
})
