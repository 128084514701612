<template>
  <router-link class="navbar-item" :to="to" :class="isActive ? 'is-active' : null" v-bind="$attrs">
    <span class="navbar-item-icon" :class="isVertical ? ['mb-1'] : ['mr-3']">
      <slot name="icon" />
    </span>
    <span class="navbar-item-label" :class="isVertical ? ['f-2'] : null">
      <slot>
        {{ title }}
      </slot>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    title: String,
    to: [String, Object],
  },
  computed: {
    isActive() {
      return this.$route.name === this.to.name
    },
    isVertical() {
      return this.$mq.value === 'xs'
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.navbar-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 200px;
  margin: 0 1rem;
  transition: background-color 0.1s;
  color: hsl(var(--color-base-base), calc(var(--color-base-lightness) + 50%));

  &.is-active {
    color: var(--color-foreground);
  }

  &:focus,
  &:hover {
    background-color: hsla(var(--color-foreground-base), var(--color-foreground-lightness), 0.1);
  }

  .is-size-xs & {
    flex-direction: column;
    width: auto;
  }
}
</style>
