<template>
  <transition name="fade" appear>
    <div class="spinner-area">
      <Spinner v-bind="$attrs" />
    </div>
  </transition>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  components: { Spinner },
}
</script>

<style lang="scss">
@import '../../styles/variables.scss';

.spinner-area {
  position: absolute;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity, backdrop-filter;
  transition-duration: 0.5s;
  opacity: 1;
  background: var(--color-base);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  backdrop-filter: none;
}
</style>
