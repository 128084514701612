<template>
  <div class="spinner" role="presentation" :style="style">
    <div class="spinner-circle"></div>
    <div class="spinner-circle"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 64,
    },
  },
  computed: {
    style() {
      const size = `${this.size}px`
      return {
        width: size,
        height: size,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../styles/variables.scss';

$duration: 1s;

.spinner {
  position: relative;
}

.spinner-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: currentColor;
  opacity: 0.5;
  border-radius: 50%;
  color: var(--color-primary);

  animation: scaleInOut $duration ease-in-out 0s infinite;

  & + & {
    animation-delay: math.div($duration, 2);
  }
}

@keyframes scaleInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
}
</style>
