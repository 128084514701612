export class TheknoError extends Error {
  toString() {
    let baseMessage = `${this.message}\nCode: ${this.code}`
    if (this.instructions) {
      baseMessage += '\n\n' + this.instructions
    }
    return baseMessage
  }
}

export class TheknoNoApiError extends TheknoError {
  constructor(message = 'Unable to determine API endpoint') {
    super(message)
    this.code = 'THEKNO_NOAPI'
    this.instructions =
      'Thekno was unable to find the API endpoint used to fetch data. ' +
      'Either you configure one by delivering a config through the `/thekno-env.json`-endpoint (see docs) ' +
      'or enable API access through the `/api` endpoint on this origin.'
  }
}

export function errorToHtml(e, title) {
  return `
    <div style="max-width: 600px; margin: 5rem auto; color: crimson; font-family: sans-serif">
      <h1 style="margin-bottom: .5em">${title}</h1>
      <pre style="white-space: pre-wrap">${String(e)}</pre>
    </div>`
}
