<template>
  <div class="paginator-touch-loader" :style="style" @animationend="onAnimationEnd">
    <app-spinner-area v-if="paginator.isLoading" />
  </div>
</template>

<script>
import { deferred } from '../../util'

const ANIMATION_TIME = 0.35

export default {
  inject: ['paginator'],
  data() {
    return {
      start: null,
      offset: null,
      snap: null,
      isSnapping: null,
    }
  },
  computed: {
    elWidth() {
      const width = this.$el?.getBoundingClientRect?.()?.width
      return width ?? this.$windowWidth.value
    },
    style() {
      const { offset, snap, isSnapping } = this
      const { hasPrev, hasNext } = this.paginator

      // if we have no offset nobody feels touchy
      if (offset === null) {
        return null
      }

      // if the swipe direction is forward, but there is no next page we don’t do anything
      // same goes for backward and previous pages
      const isForward = offset > 0
      if ((isForward && !hasNext) || (!isForward && !hasPrev)) {
        return null
      }

      const [left, right] = isForward ? ['100%', 'auto'] : ['auto', '100%']
      const opacity = Math.min(1, 0.35 + Math.abs(offset) / this.elWidth)
      const animation = isSnapping ? 'touch-loader-snap' : 'touch-loader-end'

      return [
        { left, right },
        snap
          ? {
              animation: `${animation} ${ANIMATION_TIME}s ease-out forwards`,
              '--opacity-start': opacity,
              '--opacity-end': isForward ? offset < snap : offset > snap ? 0 : 1,
              '--snap-start': `${-offset}px`,
              '--snap-end': `${snap}px`,
            }
          : {
              transition: 'transform 0.05s, opacity .05s',
              transform: `translateX(${-offset}px)`,
              opacity,
            },
      ]
    },
  },
  mounted() {
    this.paginator.$on('touchnav-start', this.setStart)
    this.paginator.$on('touchnav-move', this.setOffset)
    this.paginator.$on('touchnav-end', this.triggerNav)
  },
  beforeUnmount() {
    this.paginator.$off('touchnav-start', this.setStart)
    this.paginator.$off('touchnav-move', this.setOffset)
    this.paginator.$off('touchnav-end', this.triggerNav)
  },
  methods: {
    setStart(event) {
      if (!this.paginator.currentRequest) {
        this.resetTouchNav()
        this.start = event.touches[0].clientX
      }
    },
    setOffset(event) {
      if (this.start) {
        const offset = this.start - event.touches[0].clientX
        // Protection against over-scroll.
        // We want the overlay to stay in place over the paginator.
        this.offset = offset < 0 ? Math.max(-this.elWidth, offset) : Math.min(this.elWidth, offset)
      }
    },
    triggerNav() {
      const { elWidth, offset } = this
      if (offset !== null) {
        const isForward = offset > 0
        const snap = isForward ? -elWidth : elWidth
        this.isSnapping = deferred()
        this.isSnapping.then(() => {
          this.isSnapping = null
        })
        // safe-guard against missing animationend events
        setTimeout(() => {
          this.isSnapping && this.isSnapping.resolve()
        }, ANIMATION_TIME * 1.05 * 1000)
        if (Math.abs(offset) > elWidth / 3) {
          this.snap = snap
          this.paginator[isForward ? 'next' : 'prev']({ wait: this.isSnapping })
        } else {
          this.snap = -snap
        }
      }
    },
    async onAnimationEnd(event) {
      if (event.animationName === 'touch-loader-snap') {
        this.isSnapping.resolve()
        await (this.paginator.currentRequest || Promise.resolve())
      } else {
        this.resetTouchNav()
      }
    },
    resetTouchNav() {
      this.offset = null
      this.start = null
      this.snap = null
      this.isSnapping = null
    },
  },
}
</script>

<style lang="scss">
@import '../../styles/variables';

.paginator-touch-loader {
  position: absolute;
  background: var(--color-base);
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

@keyframes touch-loader-snap {
  from {
    transform: translateX(var(--snap-start));
    opacity: var(--opacity-start);
  }
  100% {
    transform: translateX(var(--snap-end));
    opacity: var(--opacity-end);
  }
}

@keyframes touch-loader-end {
  from {
    transform: translateX(var(--snap-end));
    opacity: var(--opacity-end);
  }
  25% {
    transform: translateX(var(--snap-end));
    opacity: var(--opacity-end);
  }
  to {
    transform: translateX(var(--snap-end));
    opacity: 0;
  }
}
</style>
