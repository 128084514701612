<template>
  <main class="page tw-py-5" style="background-color: var(--color-base)">
    <slot />
  </main>
</template>

<script lang="ts" setup>
import { computed, watchEffect } from 'vue'
import { useEnv } from '../../app'

const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    icon?: string
    noTitlePrefix?: boolean
  }>(),
  {
    noTitlePrefix: false,
  },
)
const env = useEnv()

const fullTitle = computed(() => {
  const name = env.value.name || 'thekno'
  if (props.title) {
    return props.noTitlePrefix ? props.title : `${name} - ${props.title}`
  } else {
    return name
  }
})

if (!import.meta.env.SSR) {
  watchEffect(() => {
    document.title = fullTitle.value
  })

  watchEffect(() => {
    const description = props.description || env.value.description || ''
    const descriptionEl = document.querySelector('meta[name="description"]')
    if (descriptionEl) {
      descriptionEl.setAttribute('content', description)
    }
  })

  watchEffect(() => {
    const icon = props.icon ?? env.value.favicon
    const iconEl = document.querySelector('link[rel="icon"]')
    if (iconEl && icon) {
      iconEl.setAttribute('href', icon)
      if (icon.endsWith('.svg')) {
        iconEl.setAttribute('type', 'image/svg+xml')
      }
    }
  })
}
</script>
