<template>
  <div class="player-drawer glass px-4 py-3" :class="{ 'is-open': isOpen }" v-bind="aria">
    <div class="player-drawer-global mb-2">
      <PlayerDrawerIcon :is-open="true" class="mr-2" @click="$emit('close')" />
      <PlayerExtraControls style="flex: 1; justify-content: space-between" />
    </div>

    <PlayerTrackInfo is-vertical is-centered class="player-drawer-meta align-center mb-4" />

    <div class="player-drawer-track">
      <PlayerProgress class="mb-2" />
      <PlayerControls />
    </div>
  </div>
</template>

<script>
import bodyScroll from 'body-scroll-toggle'

import PlayerControls from './PlayerControls.vue'
import PlayerDrawerIcon from './PlayerDrawerIcon.vue'
import PlayerExtraControls from './PlayerExtraControls.vue'
import PlayerProgress from './PlayerProgress.vue'
import PlayerTrackInfo from './PlayerTrackInfo.vue'

export default {
  components: {
    PlayerDrawerIcon,
    PlayerControls,
    PlayerExtraControls,
    PlayerProgress,
    PlayerTrackInfo,
  },
  props: {
    isOpen: Boolean,
  },
  computed: {
    aria() {
      return this.isOpen
        ? {
            role: 'dialog',
            'aria-modal': 'true',
          }
        : null
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        bodyScroll.disable()
      } else {
        bodyScroll.enable()
      }
    },
    $route() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.player-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - var(--screen-bottom-padding));
  transform: translateY(120%);
  pointer-events: none;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.3, 0, 0, 1);
  transition-property: opacity, transform;

  &.is-open {
    transform: translateY(calc(var(--screen-bottom-padding) * -1));
    opacity: 1;
    pointer-events: all;
  }
}

.player-drawer-track {
  flex: 0 0 auto;
}

.player-drawer-meta {
  min-height: 0;
  flex: 1 1 auto;
}

.player-drawer-global {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
