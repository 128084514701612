import { Howl, Howler } from 'howler'
// @ts-ignore
import { clamp } from './util'
// @ts-ignore
import type { EventBusKey } from '@vueuse/core'
import { useEventBus } from '@vueuse/core'
import { computed, createApp, reactive, ref, watch, watchEffect } from 'vue'
import { mapRef } from './store'
import { TheknoEnvironment, TrackService } from './types'

type HowlerEvent = { soundId: number }
type HowlerErrorEvent = HowlerEvent & { error: unknown }
type SourceFormat = 'mp3' | 'opus' | 'aac'
type Fn = () => void

type TrackControl = {
  updateSeek: () => void
  destroy: () => void
}

type Source = {
  src: string
  format: SourceFormat
}

export type TrackMetadata = {
  id: number | string
  collectionId: number | string
  title: string
  description: string
  artist?: string
  album?: string
  image?: string
}

export type HowlerTrack = TrackMetadata & {
  isStream: boolean
  sources: Source[]
  howlerData: {
    src: string[]
    format: SourceFormat[]
  }
}

type Track = {
  duration: number
  progress: number
  progressPercentage: number
  isStream: boolean
  metadata: TrackMetadata
}

export type Queue = {
  tracks: HowlerTrack[]
  prevTrack: HowlerTrack | null
  nextTrack: HowlerTrack | null
  hasPrev: boolean
  hasNext: boolean
  prev: Fn
  next: Fn
  reset: Fn
  replace: (tracks: HowlerTrack[], autoPlay?: boolean) => void
  play: (id?: number) => void
}

export type Player = {
  currentTrack: null | Track
  isPaused: boolean
  isStopped: boolean
  queue: Queue
  isPlaying: boolean
  canSeek: boolean
  isMuted: boolean
  volume: number
  play: Fn
  playTrack: (trackData: unknown) => void
  pause: Fn
  togglePlay: Fn
  seek: (position: number) => void
  seekRelative: (seconds: number) => void
  mute: (state: null | boolean) => void
  stop: Fn
  replaceMetadata: (metadata: TrackMetadata) => void
}

type TrackServiceCallback = (metadata: TrackMetadata) => void
type TrackServiceSubscriber = (
  url: string,
  callback: TrackServiceCallback,
) => Promise<{
  start: Fn
  pause: Fn
  destroy: Fn
}>

export const PLAYER_LOAD: EventBusKey<HowlerEvent> = Symbol('player:load')
export const PLAYER_LOAD_ERROR: EventBusKey<HowlerErrorEvent> = Symbol('player:loadError')
export const PLAYER_PLAY: EventBusKey<HowlerEvent> = Symbol('player:play')
export const PLAYER_PLAY_ERROR: EventBusKey<HowlerErrorEvent> = Symbol('player:playError')
export const PLAYER_MUTE: EventBusKey<boolean> = Symbol('player:mute')
export const PLAYER_END: EventBusKey<HowlerEvent> = Symbol('player:end')
export const PLAYER_PAUSE: EventBusKey<HowlerEvent> = Symbol('player:pause')
export const PLAYER_STOP: EventBusKey<HowlerEvent> = Symbol('player:stop')
export const PLAYER_VOLUME: EventBusKey<HowlerEvent> = Symbol('player:volume')
export const PLAYER_RATE: EventBusKey<HowlerEvent> = Symbol('player:rate')
export const PLAYER_SEEK: EventBusKey<HowlerEvent> = Symbol('player:seek')
export const PLAYER_FADE: EventBusKey<HowlerEvent> = Symbol('player:fade')
export const PLAYER_UNLOCK: EventBusKey<HowlerEvent> = Symbol('player:unlock')

const { emit: emitLoad } = useEventBus(PLAYER_LOAD)
const { emit: emitLoadError } = useEventBus(PLAYER_LOAD_ERROR)
const { emit: emitPlay } = useEventBus(PLAYER_PLAY)
const { emit: emitPlayError } = useEventBus(PLAYER_PLAY_ERROR)
const { emit: emitMute } = useEventBus(PLAYER_MUTE)
const { emit: emitEnd } = useEventBus(PLAYER_END)
const { emit: emitPause } = useEventBus(PLAYER_PAUSE)
const { emit: emitStop } = useEventBus(PLAYER_STOP)
const { emit: emitVolume } = useEventBus(PLAYER_VOLUME)
const { emit: emitRate } = useEventBus(PLAYER_RATE)
const { emit: emitSeek } = useEventBus(PLAYER_SEEK)
const { emit: emitFade } = useEventBus(PLAYER_FADE)
const { emit: emitUnlock } = useEventBus(PLAYER_UNLOCK)

function ensureNumber(value: unknown, _default = 0) {
  if (typeof value === 'number' && !isNaN(value)) {
    return value
  }

  return _default
}

function createQueue(player: Player) {
  const tracks = ref<HowlerTrack[]>([])
  const currentTrackIndex = computed<number>(() => {
    return tracks.value.findIndex(
      (track: HowlerTrack) => player.currentTrack && player.currentTrack.metadata.id === track.id,
    )
  })
  const prevTrack = computed<HowlerTrack | null>(
    () => tracks.value[currentTrackIndex.value - 1] ?? null,
  )
  const nextTrack = computed<HowlerTrack | null>(
    () => tracks.value[currentTrackIndex.value + 1] ?? null,
  )
  const hasPrev = computed<boolean>(() => prevTrack.value !== null)
  const hasNext = computed<boolean>(() => nextTrack.value !== null)

  function prev() {
    if (player.canSeek && player.currentTrack && player.currentTrack.progress > 3) {
      player.seek(0)
    } else if (hasPrev.value) {
      player.playTrack(prevTrack.value)
    }
  }

  function next() {
    if (hasNext.value) {
      player.playTrack(nextTrack.value)
    }
  }

  function reset() {
    tracks.value = []
  }

  function replace(_tracks: HowlerTrack[], autoPlay = true) {
    tracks.value = [..._tracks]
    if (autoPlay) {
      play()
    }
  }

  function play(id?: number) {
    const track =
      typeof id === 'undefined'
        ? tracks.value[0]
        : tracks.value.find((track: HowlerTrack) => track.id === id)
    if (track) {
      player.playTrack(track)
    }
  }

  return reactive({
    tracks,
    prevTrack,
    nextTrack,
    hasPrev,
    hasNext,
    prev,
    next,
    reset,
    replace,
    play,
  })
}

function createPlayer(): Player {
  let currentHowler: Howl | null = null
  let trackControl: TrackControl | null = null

  const { setInterval, clearInterval } = window

  const currentTrack = ref<Track | null>(null)
  const isPaused = ref<boolean>(false)
  const isStopped = ref<boolean>(true)
  const queue = ref<Queue>()
  const isPlaying = computed<boolean>(() => isStopped.value === false && isPaused.value === false)
  const canSeek = computed<boolean>(
    () => currentTrack.value !== null && !currentTrack.value.isStream,
  )
  const isMuted = mapRef<boolean>('player.isMuted')
  const volume = mapRef<number>('player.volume')

  function playTrack(trackData: HowlerTrack) {
    const { howlerData: howlerTrackData, isStream, ...trackMetadata } = trackData

    if (trackControl) {
      trackControl.destroy()
    }

    currentHowler = new Howl({
      ...howlerTrackData,
      html5: true,
      onload(soundId) {
        if (currentTrack.value && currentHowler) {
          currentTrack.value.duration = ensureNumber(currentHowler.duration())
        }
        emitLoad({ soundId })
      },
      onloaderror(soundId, error) {
        emitLoadError({ soundId, error })
      },
      onplay(soundId) {
        isStopped.value = false
        isPaused.value = false
        emitPlay({ soundId })
      },
      onplayerror(soundId, error) {
        emitPlayError({ soundId, error })
      },
      onend(soundId) {
        if (queue.value && queue.value.hasNext) {
          queue.value.next()
        } else {
          isStopped.value = true
          isPaused.value = true
          trackControl?.destroy?.()
        }
        emitEnd({ soundId })
      },
      onpause(soundId) {
        isStopped.value = false
        isPaused.value = true
        emitPause({ soundId })
      },
      onstop(soundId) {
        isStopped.value = true
        isPaused.value = false
        emitStop({ soundId })
      },
      onvolume(soundId) {
        emitVolume({ soundId })
      },
      onrate(soundId) {
        emitRate({ soundId })
      },
      onseek(soundId) {
        trackControl?.updateSeek?.()
        emitSeek({ soundId })
      },
      onfade(soundId) {
        emitFade({ soundId })
      },
      onunlock(soundId) {
        emitUnlock({ soundId })
      },
    })

    trackControl = {
      updateSeek() {
        if (currentTrack.value && currentHowler) {
          const currentDuration = ensureNumber(currentHowler.seek())
          const progressPercentage = clamp(
            (currentDuration / currentTrack.value.duration) * 100,
            0,
            100,
          )
          currentTrack.value.progress = currentDuration
          currentTrack.value.progressPercentage = progressPercentage
        }
      },
      destroy() {
        currentHowler?.unload?.()
        clearInterval(seekUpdater)
        currentHowler = null
        trackControl = null
        currentTrack.value = null
      },
    }

    const seekUpdater = setInterval(() => {
      if (!isPaused.value && trackControl) {
        trackControl.updateSeek()
      }
    }, 250)

    currentTrack.value = reactive<Track>({
      duration: 0,
      progress: 0,
      progressPercentage: 0,
      isStream: isStream || false,
      metadata: trackMetadata,
    })

    currentHowler.play()
  }

  function play() {
    if (currentHowler && isPaused.value) {
      currentHowler.play()
    }
  }

  function pause() {
    if (currentHowler && isPlaying.value) {
      currentHowler.pause()
    }
  }

  function seek(position: number) {
    if (currentHowler) {
      currentHowler.seek(position)
    }
  }

  function seekRelative(seconds: number) {
    if (currentHowler && currentTrack.value) {
      const duration = currentTrack.value.duration || Number.POSITIVE_INFINITY
      currentHowler.seek(clamp(currentTrack.value.progress + seconds, 0, duration))
    }
  }

  function mute(state: null | boolean = null) {
    const newState = typeof state === 'boolean' ? state : !isMuted.value
    isMuted.value = newState
    Howler.mute(newState)
    emitMute(newState)
  }

  function stop() {
    if (trackControl) {
      trackControl.destroy
    }
  }

  function togglePlay() {
    if (isPaused.value) {
      play()
    } else {
      pause()
    }
  }

  function replaceMetadata(newMetadata: TrackMetadata) {
    /**
     * You only ever need to call this method if the track that is being
     * played is a stream and has no end. Otherwise, just use the playTrack method.
     */
    if (currentTrack.value) {
      currentTrack.value.metadata = newMetadata
    }
  }

  watchEffect(() => {
    Howler.volume(volume.value / 100)
  })

  const player: Player = reactive({
    currentTrack,
    isPaused,
    isStopped,
    queue,
    isPlaying,
    canSeek,
    isMuted,
    volume,
    play,
    playTrack,
    pause,
    togglePlay,
    seek,
    seekRelative,
    mute,
    stop,
    replaceMetadata,
  }) as Player

  queue.value = createQueue(player)
  return player
}

export function useMediaSession(
  env: TheknoEnvironment,
  player: Player,
  options: { seekTime: number } = { seekTime: 15 },
) {
  const { MediaMetadata } = window
  const session = navigator.mediaSession

  const actionHandlers = computed<Partial<Record<MediaSessionAction, null | (() => void)>>>(() => {
    const { canSeek } = player
    const { hasNext, hasPrev } = player.queue
    return {
      seekbackward: canSeek
        ? () => {
            player.seekRelative(-options.seekTime)
          }
        : null,
      seekforward: canSeek
        ? () => {
            player.seekRelative(options.seekTime)
          }
        : null,
      previoustrack:
        hasPrev || canSeek
          ? () => {
              player.queue.prev()
            }
          : null,
      nexttrack: hasNext
        ? () => {
            player.queue.next()
          }
        : null,
    }
  })

  const fallbackArtwork = computed<string | null>(
    () => env?.integration?.mediaSession?.fallbackArtwork ?? null,
  )

  watchEffect(() => {
    for (const [name, handler] of Object.entries(actionHandlers.value)) {
      session.setActionHandler(name as MediaSessionAction, handler)
    }
  })

  watch(
    () => player.currentTrack?.metadata,
    (metadata) => {
      navigator.mediaSession.metadata = !metadata
        ? null
        : new MediaMetadata({
            title: metadata.title,
            // TODO: when artist and album are not defined Chromium-based mobile browsers
            //       show the thekno origin address instead. There doesn’t seem to be a good
            //       way to change this. Adding an empty string will hide the URL but forces
            //       a minus-sign next to the title :(.
            artist: metadata.artist ?? env.name,
            // TODO: use the series of the recording as album here if available
            album: metadata.album ?? metadata.description ?? undefined,
            // TODO: artwork takes an array of { src, sizes, type }, but we can’t guarantee
            //       that we can deduce sizes and type directly from the source, so this is WIP
            artwork:
              metadata.image || fallbackArtwork.value
                ? [{ src: (metadata.image ?? fallbackArtwork.value) as string }]
                : undefined,
          })
    },
  )
}

const createWebSocketSubscriber = async (url: string, callback: TrackServiceCallback) => {
  const ReconnectingWebSocket = (await import('reconnecting-websocket')).default
  const rws = new ReconnectingWebSocket(url, [], { startClosed: true })
  const processEvent = (event: { data: string }) => {
    const eventData = JSON.parse(event.data)
    if (eventData?.source === 'current_track' && eventData?.event === 'update') {
      callback(eventData.payload)
    }
  }

  rws.addEventListener('message', processEvent)

  return {
    start() {
      rws.reconnect()
    },
    pause() {
      rws.close()
    },
    destroy() {
      rws.removeEventListener('message', processEvent)
      rws.close()
    },
  }
}

const createPollSubscriber = async (url: string, callback: TrackServiceCallback) => {
  let interval: ReturnType<typeof setInterval>
  const getData = () =>
    fetch(url)
      .then((res) => res.json())
      .then((data) => data.results[0])
      .then((track) => {
        callback(track)
      })
  const start = () => {
    clearInterval(interval)
    interval = setInterval(getData, 5000)
  }
  const pause = () => {
    clearInterval(interval)
  }

  return {
    start,
    pause,
    destroy: pause,
  }
}

export const createTrackServiceObservable = async (url: string, { timeOffsetSec = 0 }) => {
  const protocol = new URL(url).protocol
  const createSubscriber: TrackServiceSubscriber = ['ws:', 'wss:'].includes(protocol)
    ? createWebSocketSubscriber
    : createPollSubscriber
  let isFirstSinceStart = true

  const state = reactive<{
    metadata: TrackMetadata | null
    isPaused: boolean
    start: Fn
    pause: Fn
    destroy: Fn
  }>({
    metadata: null,
    isPaused: true,
    start() {
      isFirstSinceStart = true
      state.isPaused = false
      return subscriber.start()
    },
    pause() {
      this.isPaused = true
      return subscriber.pause()
    },
    destroy() {
      this.isPaused = true
      subscriber.destroy()
    },
  })

  const subscriber = await createSubscriber(url, (newMetadata) => {
    setTimeout(
      () => {
        if (!state.isPaused) {
          state.metadata = newMetadata
            ? {
                ...newMetadata,
                id: 'livestream',
                collectionId: 'livestream',
              }
            : null
        }
      },
      isFirstSinceStart ? 0 : timeOffsetSec * 1000,
    )
    isFirstSinceStart = false
  })

  return state
}

export function useTrackService(trackServiceConfig: TrackService, player: Player) {
  const { url, ...options } = trackServiceConfig
  const trackService = ref()
  createTrackServiceObservable(url, options).then((_trackService) => {
    trackService.value = _trackService
  })

  watchEffect(() => {
    const isStream = player?.currentTrack?.isStream
    if (typeof isStream !== 'undefined') {
      if (isStream) {
        trackService.value?.start?.()
      } else {
        trackService.value?.pause?.()
      }
    }
  })

  watch(
    () => trackService.value?.metadata,
    (newMetadata) => {
      player.replaceMetadata(newMetadata)
    },
    { deep: true },
  )
}

export default {
  install(app: ReturnType<typeof createApp>, options: { env: TheknoEnvironment }) {
    const player = createPlayer()

    if (options.env?.live?.trackService) {
      useTrackService(options.env.live.trackService, player)
    }

    if (typeof window.MediaMetadata !== 'undefined' && navigator.mediaSession) {
      useMediaSession(options.env, player)
    }

    // this installs the player as a variable available to all Vue components
    // and instances available as this.$player. For the projected scope and size
    // of the thekno project this seems like a reasonable choice, but obviously
    // should be refactored if we extend it beyond that.
    app.config.globalProperties.$player = player
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $player: Player
  }
}
