<template>
  <Button
    v-bind="$attrs"
    look="transparent"
    :aria-label="t(isLiked ? 'like_has_liked' : 'like_has_not_liked')"
    :title="t(isLiked ? 'like_unset' : 'like_set')"
    @click="isLiked = !isLiked"
  >
    <template #icon>
      <icon-feather-heart class="heart" :class="{ 'is-active': isLiked }" />
    </template>
  </Button>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { mapRef } from '../../store'
import Button from './Button.vue'

type Id = number | string

const props = defineProps({
  collection: {
    type: String as PropType<'broadcasts' | 'recordings' | 'series'>,
    required: true,
    validator(value: string): boolean {
      return ['broadcasts', 'recordings', 'series'].includes(value)
    },
  },
  id: {
    type: [String, Number] as PropType<Id>,
    required: true,
  },
})
const { t } = useI18n()

const bookmarks = mapRef<{ broadcasts: Id[]; recordings: Id[]; series: Id[] }>(
  (obj) => obj.bookmarks,
)
const isLiked = computed({
  get() {
    return bookmarks.value[props.collection].indexOf(props.id) !== -1
  },
  set(isLiked) {
    bookmarks.value[props.collection] = isLiked
      ? [props.id, ...bookmarks.value[props.collection]]
      : bookmarks.value[props.collection].filter((v) => v !== props.id)
  },
})
</script>

<i18n lang="yaml">
en:
  like_set: Bookmark
  like_unset: Remove bookmark
  like_has_liked: You’ve bookmarked this
  like_has_not_liked: You’ve not bookmarked this

de:
  like_set: Merken
  like_unset: Nicht weiter merken
  like_has_liked: Du hast dir das gemerkt
  like_has_not_liked: Du hast dir das nicht gemerkt
</i18n>

<style lang="postcss" scoped>
.heart.is-active {
  color: hsl(349, 86%, 50%);
}
.heart.is-active :deep(path) {
  fill: currentColor;
}
</style>
