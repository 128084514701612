<template>
  <div class="d-flex align-center">
    <PlayerControl
      role="switch"
      :aria-label="queueLabel"
      :title="queueLabel"
      :aria-checked="$route.name === 'queue' ? 'true' : 'false'"
      @click="toggleQueue"
    >
      <icon-ph-queue-fill />
    </PlayerControl>
    <div class="player-volume d-flex align-center mx-2">
      <PlayerControl
        class="mr-2"
        :class="$player.isMuted ? 'is-danger' : null"
        :title="muteLabel"
        @click="$player.mute"
      >
        <icon-feather-volume-x v-if="$player.isMuted" />
        <icon-feather-volume v-else-if="$player.volume < 100 / 3" />
        <icon-feather-volume-1 v-else-if="$player.volume < (100 / 3) * 2" />
        <icon-feather-volume-2 v-else />
      </PlayerControl>
      <SeekableProgress
        v-model:model-value="$player.volume"
        :aria-label="$t('player_volume')"
        :knob-label="$t('player_set_volume')"
      />
    </div>
  </div>
</template>

<script>
import SeekableProgress from '../SeekableProgress.vue'
import PlayerControl from './PlayerControl.vue'

export default {
  components: { PlayerControl, SeekableProgress },
  computed: {
    muteLabel() {
      return this.$t(this.$player.isMuted ? 'player_unmute' : 'player_mute')
    },
    queueLabel() {
      return this.$t(this.$route.name === 'queue' ? 'player_queue_hide' : 'player_queue_show')
    },
  },
  methods: {
    toggleQueue() {
      if (this.$route.name === 'queue') {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'queue' })
      }
    },
  },
}
</script>

<style>
.player-volume {
  width: 125px;
}
</style>

<i18n lang="yaml">
en:
  player_mute: Mute
  player_unmute: Unmute
  player_volume: Volume
  player_set_volume: Set Volume
  player_queue_hide: Hide Queue
  player_queue_show: Show Queue

de:
  player_mute: Ton aus
  player_unmute: Ton an
  player_volume: Lautstärke
  player_set_volume: Lautstärke einstellen
  player_queue_hide: Warteschlange ausblenden
  player_queue_show: Warteschlange anzeigen
</i18n>
