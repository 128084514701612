import { TheknoNoApiError } from './error'

const { location, fetch } = window
const defaultApiPath = `${location.origin}/api/v1`
const apiPath = import.meta.env.VITE_APP_APIPATH || defaultApiPath

function retryAPI(path = apiPath) {
  return fetch(`${path}/`, { cache: 'no-cache' })
    .then((res) => res.json())
    .then(
      () => ({ apiPath: path }),
      () => {
        throw new TheknoNoApiError()
      },
    )
}

function handleEarlyResponseFailure(e) {
  if (/Failed to fetch/.test(e.message)) {
    // Failures before a preliminary response is available indicate a CORS issue
    return retryAPI()
  }

  throw e
}

function handleResponseProcessingFailure(e) {
  if (e instanceof SyntaxError) {
    if (typeof window.console !== 'undefined') {
      window.console.warn(
        'A thekno-env.json was found but could not be parsed as JSON. ' +
          'If you have defined one, you might want to check it for syntax errors.',
      )
    }
    // Looks like the response could not be parsed as JSON.
    // Maybe the default route handler kicked in returning the index.html.
    // Try the current origin for the API and if that fails give up.
    return retryAPI()
  }

  // We’re out of ideas what might have gone wrong.
  // Rethrow and let others handle this.
  throw e
}

export function loadEnvironment() {
  return fetch(`${location.origin}/thekno-env.json`)
    .then((res) => res.json(), handleEarlyResponseFailure)
    .catch(handleResponseProcessingFailure)
}
