import { computedAsync } from '@vueuse/core'
import { computed, ComputedRef, Ref, ref, watchEffect } from 'vue'
import { Category, Collection, DataSources, Id, TheknoEnvironment } from './types'

// We cast these to non-undefined refs because thekno is initialized
// and registers values for these refs before any code is executed that
// uses them. This way we can simplify type handling a lot.
const _env = ref<TheknoEnvironment>() as Ref<TheknoEnvironment>
const _dataSources = ref<DataSources>() as Ref<DataSources>
const _categories = computedAsync<Category[]>(
  async () =>
    _dataSources.value.categories ? (await _dataSources.value.categories.list('')).results : [],
  [],
)
const _categoryMap = computed(() =>
  Object.fromEntries(_categories.value.map((category) => [category.id, category])),
)

export function registerEnv(env: TheknoEnvironment) {
  _env.value = env
}

export function registerDataSources(dataSources: DataSources) {
  _dataSources.value = dataSources
}

export function useDataSources(): ComputedRef<DataSources> {
  return computed(() => _dataSources.value as DataSources)
}

// FIXME types: there must be a way to infer T from the passed name so that we don’t have to cast it manually
export function useDataSource<T>(name: keyof DataSources): ComputedRef<Collection<T>> {
  return computed(() => _dataSources.value?.[name] as Collection<T>)
}

export function useEnv(): ComputedRef<TheknoEnvironment> {
  return computed(() => _env.value as TheknoEnvironment)
}

export function useImage(imageId: Ref<Id | null>) {
  const image = ref<{ url: string } | null>(null)
  watchEffect(async () => {
    if (imageId.value !== null && _env.value?.apiPath) {
      image.value = { url: `${_env.value.apiPath}/images/${imageId.value}/render/` }
    } else {
      image.value = null
    }
  })
  return image
}

function* generateHierarchy(categoryId: Id): Iterable<Category> {
  const category = _categoryMap.value[categoryId]
  if (category) {
    yield category
    if (category.parentId) {
      yield* generateHierarchy(category.parentId)
    }
  }
}

export function useCategoryHierarchies(categoryIds: Ref<Id[]>): ComputedRef<Category[][]> {
  return computed(() => {
    const hierarchies = categoryIds.value
      .map((id) => Array.from(generateHierarchy(id)))
      .filter((hierarchy) => hierarchy.length > 0)
      .map((hierarchy) => hierarchy.reverse())

    // A lot of hierarchies look like this
    //  * Rostock
    //  * Rostock > Kultur
    //  * Rostock > Kultur > Veranstaltungen
    //  * Rostock > Veranstaltungen
    // We only want to use the hierarchies that are not subsets of other hierarchies.
    // (Rostock > Kultur > Veranstaltungen, Rostock > Veranstaltungen)
    const generateHierarchyPath = (categories: Category[]) =>
      categories.map((category) => category.id).join('>')
    const hierarchPaths = hierarchies.map(generateHierarchyPath)
    return hierarchies.filter((categories, index) => {
      const path = hierarchPaths[index]
      const matchingSiblingIndex = hierarchPaths
        .filter((_, _index) => _index !== index)
        .findIndex((siblingPath) => siblingPath.startsWith(path))
      return matchingSiblingIndex === -1
    })
  })
}
