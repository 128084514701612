<template>
  <app-container>
    <footer class="center">
      <router-link
        v-if="$route.name !== 'about'"
        :to="{ name: 'about' }"
        class="button is-transparent mb-3"
      >
        {{ $t('provider.about', [env.name]) }}
      </router-link>

      <div class="d-flex justify-center">
        <a
          v-for="{ id, url, label } in links"
          :key="id"
          v-t="label"
          :href="url"
          class="mr-3"
          target="_blank"
          rel="noopener"
        ></a>
      </div>
    </footer>
  </app-container>
</template>

<script>
export default {
  inject: ['env'],
  computed: {
    links() {
      return Object.entries(this.env.provider || {}).map(([id, url]) => ({
        id,
        url,
        label: `provider.${id}`,
      }))
    },
  },
}
</script>

<i18n lang="yaml">
en:
  provider.legal: Legal
  provider.contact: Contact
  provider.privacy: Privacy
  provider.about: 'About {0}'

de:
  provider.legal: Impressum
  provider.contact: Kontakt
  provider.privacy: Datenschutz
  provider.about: 'Über {0}'
</i18n>
