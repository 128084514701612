<template>
  <PlayerControl
    class="player-drawer-icon"
    :style="{ transform: `rotate(${isOpen ? 180 : 0}deg)` }"
    @click="$emit('click')"
  >
    <icon-feather-chevron-up :style="{ width: '32px', height: '32px', strokeWidth: 1 }" />
  </PlayerControl>
</template>

<script>
import PlayerControl from './PlayerControl.vue'

export default {
  components: { PlayerControl },
  props: {
    isOpen: Boolean,
  },
}
</script>

<style>
.player-drawer-icon {
  -webkit-touch-callout: none;
}
</style>
