<template>
  <div class="field">
    <slot />
  </div>
</template>

<style lang="scss">
.field {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
</style>
