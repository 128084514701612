<template>
  <div v-if="shouldDisplay" class="paginator-indicator mt-4">
    <div v-if="paginator.numberOfPages <= 10" class="paginator-indicator-bubbles">
      <span
        v-for="page in pages"
        :key="page"
        class="paginator-indicator-bubble"
        :class="{ 'is-active': paginator.page === page }"
      ></span>
    </div>
    <span v-else class="opaque-7">{{ paginator.page }} / {{ paginator.numberOfPages }}</span>
  </div>
</template>

<script>
export default {
  inject: ['paginator'],
  computed: {
    pages() {
      return new Array(this.paginator.numberOfPages).fill(0).map((_, index) => index + 1)
    },
    shouldDisplay() {
      return (
        this.paginator.useTouch &&
        this.paginator.behavior === 'replace' &&
        this.paginator.numberOfPages > 1
      )
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';
@import '../../styles/variables';

$size: 0.95rem;

.paginator-indicator {
  --inactive-color: var(--color-surface);
  --active-color: hsl(var(--color-surface-base), calc(var(--color-surface-lightness) + 30%));

  text-align: center;
}

.paginator-indicator-bubbles {
  display: grid;
  grid-template-columns: repeat(auto-fit, $size);
  grid-gap: ($size * 0.65);
  justify-content: center;
}

.paginator-indicator-bubble {
  background: var(--inactive-color);
  width: $size;
  height: $size;
  border-radius: 50%;
  position: relative;
  border: math.div($size, 4.5) solid var(--inactive-color);

  &.is-active {
    background: var(--active-color);
  }
}
</style>
