<template>
  <div class="player-controls">
    <PlayerControl
      v-if="!isMinimal"
      :title="$t('player_prev_track')"
      :disabled="!$player.queue.hasPrev && !$player.canSeek"
      @click="$player.queue.prev"
    >
      <icon-feather-skip-back />
    </PlayerControl>

    <PlayerSeekControl direction="backward" />

    <PlayerControl
      class="is-primary"
      :class="isMinimal ? null : 'mx-2'"
      :title="$t($player.isPlaying ? 'player_pause' : 'player_play')"
      @click="$player.togglePlay"
    >
      <icon-feather-pause-circle v-if="$player.isPlaying" :style="playIconStyle" />
      <icon-feather-play-circle v-else :style="playIconStyle" />
    </PlayerControl>

    <PlayerSeekControl v-if="!isMinimal" direction="forward" />

    <PlayerControl
      v-if="!isMinimal"
      :disabled="!$player.queue.hasNext"
      :title="$t('player_next_track')"
      @click="$player.queue.next"
    >
      <icon-feather-skip-forward />
    </PlayerControl>
  </div>
</template>

<script>
import PlayerControl from './PlayerControl.vue'
import PlayerSeekControl from './PlayerSeekControl.vue'

export default {
  components: {
    PlayerSeekControl,
    PlayerControl,
  },
  props: {
    isMinimal: Boolean,
  },
  computed: {
    playIconStyle() {
      const size = this.isMinimal ? '32px' : '38px'
      return { width: size, height: size, strokeWidth: 1 }
    },
  },
}
</script>

<style>
.player-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
</style>

<i18n lang="yaml">
en:
  player_play: 'Play'
  player_pause: 'Pause'
  player_next_track: 'Next Track'
  player_prev_track: 'Previous Track'

de:
  player_play: 'Wiedergabe fortsetzen'
  player_pause: 'Wiedergabe pausieren'
  player_next_track: 'Nächster Titel'
  player_prev_track: 'Vorheriger Titel'
</i18n>
