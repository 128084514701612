import PauseIcon from '~icons/feather/pause'
import PauseCircleIcon from '~icons/feather/pause-circle'
import PlayIcon from '~icons/feather/play'
import PlayCircleIcon from '~icons/feather/play-circle'
import PlayingIcon from './PlayingIcon.vue'

export const playPauseMixin = {
  props: {
    tracks: {
      type: Array,
      validate(value) {
        return Array.isArray(value) || value === null
      },
    },
    trackId: {
      type: [String, Number],
      required: true,
    },
    playLabel: String,
    playIcon: Object,
  },
  data() {
    return {
      isHovered: false,
      circleIcons: true,
      noPlayingIcon: false,
    }
  },
  computed: {
    isCurrentTrack() {
      const { currentTrack } = this.$player
      return (
        currentTrack && currentTrack.metadata && currentTrack.metadata.collectionId === this.trackId
      )
    },
    currentAction() {
      if (this.isCurrentTrack && this.$player.isPlaying) {
        return 'pause'
      } else if (this.isCurrentTrack && !this.$player.isPlaying) {
        return 'resume'
      } else {
        return 'play'
      }
    },
    icon() {
      if (this.isCurrentTrack && this.$player.isPlaying && (this.isHovered || this.noPlayingIcon)) {
        return this.circleIcons ? PauseCircleIcon : PauseIcon
      } else if (this.isCurrentTrack && this.$player.isPlaying) {
        return PlayingIcon
      } else {
        return this.playIcon ? this.playIcon : this.circleIcons ? PlayCircleIcon : PlayIcon
      }
    },
  },
  methods: {
    togglePlay() {
      if (this.currentAction === 'pause') {
        this.$player.pause()
      } else if (this.currentAction === 'resume') {
        this.$player.play()
      } else {
        this.$player.queue.replace(this.tracks)
      }
    },
  },
}
