<template>
  <app-card
    class="tw-w-44"
    :title="series.name"
    :description="description"
    is-vertical
    fill-image
    :image="{
      src: image?.url ?? null,
      size: null,
      autoSize: true,
      aspectRatio: 1,
      contain: true,
      fallbackSrc: env.fallbackSeriesCover,
    }"
    :link="{ to: { name: 'series', params: { id: series.id } } }"
  />
</template>

<script lang="ts">
export default {
  DEFAULT_HEIGHT: 244,
  ITEM_KEY: 'series',
}
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { useEnv, useImage } from '../../app'
import { Series } from '../../types'

const props = defineProps<{
  series: Series
}>()
const env = useEnv()
const image = useImage(computed(() => props.series.imageId))
const description = computed(() => props.series.teaser.trim() || props.series.description)
</script>
