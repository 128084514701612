import { registerSW } from 'virtual:pwa-register'
import { reactive } from 'vue'

const DEFAULT_THEME_COLOR = '#24242e'

function createElement(tag, attrs) {
  const el = document.createElement(tag)
  for (const [name, value] of Object.entries(attrs)) {
    el.setAttribute(name, value)
  }
  return el
}

function getOrigin(url, prefix) {
  return `${prefix}${new URL(url).origin}`
}

function preloadConnections(env, el = document.head) {
  // we will definitely connect to our API
  el.appendChild(
    createElement('link', { rel: 'preconnect', href: getOrigin(env.apiPath), crossorigin: '' }),
  )

  if (env.live) {
    // prefetch dns data for radio streams
    const streams = env.live.audio.sources.map((src) => src.src)
    for (const stream of streams) {
      el.appendChild(createElement('link', { rel: 'dns-prefetch', href: getOrigin(stream, '//') }))
    }

    // prefetch dns data for the track-service
    if (env.live.trackService) {
      const href = getOrigin(env.live.trackService.url, '//')
      el.appendChild(createElement('link', { rel: 'dns-prefetch', href }))
    }
  }
}

function injectWebAppMetadata(env, el = document.head) {
  if (env.theme && env.theme.base) {
    const [r, g, b] = env.theme.base
    el.appendChild(createElement('meta', { name: 'theme-color', content: `rgb(${r}, ${g}, ${b})` }))
  } else {
    el.appendChild(createElement('meta', { name: 'theme-color', content: DEFAULT_THEME_COLOR }))
  }

  if (env.integration.appleTouchIcon) {
    el.appendChild(
      createElement('link', { rel: 'apple-touch-icon', href: env.integration.appleTouchIcon }),
    )
  }
}

export function registerServiceWorker() {
  window.console.log('(PWA) Register service worker.')
  registerSW({
    onRegisteredSW() {
      window.console.log('(PWA) Service worker has been registered.')
    },
    onRegisterError(error) {
      window.console.error('(PWA) Error during service worker registration:', error)
    },
  })
}

export function registerPWA(env) {
  preloadConnections(env)
  if (env.integration) {
    injectWebAppMetadata(env)
  }
}

export const installer = reactive({
  dialog: null,
})

window.addEventListener('beforeinstallprompt', (e) => {
  installer.dialog = e
})
