<template>
  <button type="button" class="player-control" @click.exact="$emit('click', $event)">
    <slot />
  </button>
</template>

<style lang="scss">
@import '../../styles/variables';

.player-control {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-foreground-dimmed);
  transition: none 33ms cubic-bezier(0.3, 0, 0, 1);
  transition-property: color, transform;
  transform-origin: 50% 50%;
  padding: 0;
  cursor: pointer;
  position: relative;
  background: none;
  border: none;
}

.player-control:disabled {
  color: var(--color-muted);
  pointer-events: none;
}

.player-control:active {
  transform: scale(0.9) !important;
}

.player-control:hover,
.player-control:focus {
  color: var(--color-foreground);
  outline: none;
}

.player-control.is-primary:hover,
.player-control.is-primary:focus {
  transform: scale(1.06);
}

.player-control.is-danger {
  color: var(--color-danger);
}

.player-control[aria-checked='true'] {
  color: var(--color-accent);
}

.player-control[aria-checked='true']::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
}
</style>
