<template>
  <nav class="navbar">
    <app-container>
      <div class="navbar-items" :style="{ 'grid-template-columns': `repeat(3, 1fr)` }">
        <NavBarItem :title="$t('nav_start')" :to="{ name: 'home' }">
          <template #icon>
            <icon-feather-home />
          </template>
        </NavBarItem>
        <NavBarItem :title="$t('nav_search')" :to="{ name: 'search' }">
          <template #icon>
            <icon-feather-search />
          </template>
        </NavBarItem>
        <NavBarItem :title="$t('nav_profile')" :to="{ name: 'profile' }">
          <template #icon>
            <icon-feather-user />
          </template>
        </NavBarItem>
      </div>
    </app-container>
  </nav>
</template>

<script>
import NavBarItem from './NavBarItem.vue'

export default {
  components: { NavBarItem },
}
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/util';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: hsl(var(--color-base-base), calc(var(--color-base-lightness) + 5%));
  will-change: transform;
  @include elevation(8);
  transition: transform 0.2s cubic-bezier(0.3, 0, 0, 1);

  .is-size-xs & {
    top: auto;
    bottom: calc(var(--navbar-height) * -1);
    transform: translateY(calc(var(--screen-bottom-padding) * -1));
  }
}

.navbar-items {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: var(--navbar-height);

  .is-size-xs & {
    display: grid;
  }
}
</style>

<i18n lang="yaml">
en:
  nav_start: Start
  nav_search: Search
  nav_profile: Profile

de:
  nav_start: Start
  nav_search: Suche
  nav_profile: Profil
</i18n>
